import { md5 } from 'js-md5';

export const lastFMAPIKey = 'd558d4f8e7e1dbe3cbd2f78225f9cff2';
export const lastFMAPISharedSecret = 'e14c45ab0b110c070d296c827c379884';

export const LASTFM_SESSION_KEY_LS_KEY = 'linefest_lastfm_session_key';

export async function redirectToLastfmAuthCodeFlow() {
    const params = new URLSearchParams();
    params.append('api_key', lastFMAPIKey);
    params.append('cb', `${import.meta.env.VITE_REDIRECT_URI}/callback`);

    document.location = `http://www.last.fm/api/auth/?${params.toString()}`;
}
