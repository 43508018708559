declare const i18next: any;

export const hideSelectionModes = () => {
    const $node = document.querySelector('.selection-mode');
    if ($node) {
        $node.classList.add('d-none');
    }
};

export const displayLoader = ($node: any, message: string) => {
    // loader
    if ($node) {
        $node.innerHTML = '';

        const $loaderContainer = document.createElement('div');
        $loaderContainer.classList.add('d-flex', 'flex-column', 'align-items-center', 'loader-container');

        const $loader = document.createElement('div');
        $loader.classList.add('loader', 'mb-5');
        $loaderContainer.appendChild($loader);

        const $loaderMessage = document.createElement('div');
        $loaderMessage.classList.add('mb-3', 'message');
        $loaderMessage.innerHTML = i18next.t(message);

        $loaderContainer.appendChild($loaderMessage);

        $node.appendChild($loaderContainer);
    }
};
