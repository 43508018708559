import * as bootstrap from 'bootstrap';
import Gradient from 'javascript-color-gradient';

import InternalDataManager from './internal-data-manager.js';
import UserManager from './user-manager';

import {
    LINEFEST_SELECTED_FESTIVAL,
    LINEFEST_SELECTED_FESTIVAL_EDITION,
    LINEFEST_SELECTED_FESTIVAL_EDITION_NAME,
    PROVIDERS,
    cleanProviderName,
    formatVenueName,
    getFestivalData,
    slugify,
    userFollowArtistOnSelectedProvider,
} from './utils';
import { calculateFestivalScoreForUser } from './engine.js';
import { getBrowserLanguage } from './i18n-utils.js';

let DetailFestivalModalManagerInstance: any;

declare const dayjs: any;
declare const i18next: any;

export class DetailFestivalModalManager {
    $modal: any = null;
    modal: any = null;

    $filtersMatchesPanel: any = null;

    $filtersDatesPanel: any = null;
    $filtersVenuesPanel: any = null;
    $artistsList: any = null;

    $alphaSort: any = null;
    $matchSort: any = null;
    $timeSort: any = null;

    selectedFilterDate = 'a-z';

    displayMatches = false;

    constructor() {
        if (!DetailFestivalModalManagerInstance) {
            DetailFestivalModalManagerInstance = this;
        }
        return DetailFestivalModalManagerInstance;
    }

    init() {
        return new Promise((detailFestivalModalInitResolve, detailFestivalModalInitReject) => {
            this.$modal = document.getElementById('festivalModal');

            if (this.$modal) {
                this.modal = new bootstrap.Modal(this.$modal);
                this.$modal.addEventListener('hidden.bs.modal', (event: any) => {
                    localStorage.removeItem(LINEFEST_SELECTED_FESTIVAL);
                    localStorage.removeItem(LINEFEST_SELECTED_FESTIVAL_EDITION);
                    localStorage.removeItem(LINEFEST_SELECTED_FESTIVAL_EDITION_NAME);
                });
            }

            detailFestivalModalInitResolve(true);
        });
    }

    async show(festivalInfos: any) {
        if (this.modal) {
            const { festivalId, festivalSlug, festivalYear, festivalEditionName } = festivalInfos;
            console.log(festivalInfos);

            if (festivalInfos.festivalId !== '') {
                localStorage.setItem(LINEFEST_SELECTED_FESTIVAL, festivalId);
                localStorage.setItem(LINEFEST_SELECTED_FESTIVAL_EDITION, festivalYear);
                if (festivalEditionName !== '') {
                    localStorage.setItem(LINEFEST_SELECTED_FESTIVAL_EDITION_NAME, festivalEditionName);
                }

                if (InternalDataManager.getFestivalMainInfosById(festivalId)) {
                    const selectedFestival = Object.assign({}, InternalDataManager.getFestivalMainInfosById(festivalId));
                    const festivalData = await getFestivalData(festivalSlug, festivalYear, festivalEditionName);
                    const score = calculateFestivalScoreForUser(UserManager.userFollowingArtists, UserManager.userGenresWeightedSorted, festivalData.shows);

                    selectedFestival.matchingScores = score;
                    selectedFestival.genresStats = festivalData.genresStats;

                    InternalDataManager.selectedFestivalData = festivalData;
                    InternalDataManager.selectedFestivalDates = {};
                    InternalDataManager.selectedFestivalVenues = {};

                    // SOCIALS

                    let festivalSocialsHTML = '<div class="festival-socials">';
                    if (selectedFestival.website !== '') {
                        festivalSocialsHTML += `
                            <div class="festival-social website">
                                <a href="${selectedFestival.website}" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-globe" viewBox="0 0 16 16">
                                        <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m7.5-6.923c-.67.204-1.335.82-1.887 1.855A8 8 0 0 0 5.145 4H7.5zM4.09 4a9.3 9.3 0 0 1 .64-1.539 7 7 0 0 1 .597-.933A7.03 7.03 0 0 0 2.255 4zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a7 7 0 0 0-.656 2.5zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5zM8.5 5v2.5h2.99a12.5 12.5 0 0 0-.337-2.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5zM5.145 12q.208.58.468 1.068c.552 1.035 1.218 1.65 1.887 1.855V12zm.182 2.472a7 7 0 0 1-.597-.933A9.3 9.3 0 0 1 4.09 12H2.255a7 7 0 0 0 3.072 2.472M3.82 11a13.7 13.7 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5zm6.853 3.472A7 7 0 0 0 13.745 12H11.91a9.3 9.3 0 0 1-.64 1.539 7 7 0 0 1-.597.933M8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855q.26-.487.468-1.068zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.7 13.7 0 0 1-.312 2.5m2.802-3.5a7 7 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7 7 0 0 0-3.072-2.472c.218.284.418.598.597.933M10.855 4a8 8 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4z"/>
                                    </svg>
                                </a>
                            </div>
                        `;
                    }
                    if (selectedFestival.instagram !== '') {
                        festivalSocialsHTML += `
                            <div class="festival-social instagram">
                                <a href="https://www.instagram.com/${selectedFestival.instagram}" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-instagram" viewBox="0 0 16 16">
                                        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
                                    </svg>
                                </a>
                            </div>
                        `;
                    }
                    if (selectedFestival.facebook !== '') {
                        festivalSocialsHTML += `
                            <div class="festival-social facebook">
                                <a href="https://www.facebook.com/${selectedFestival.facebook}" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-facebook" viewBox="0 0 16 16">
                                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
                                    </svg>
                                </a>
                            </div>
                        `;
                    }
                    if (selectedFestival.twitter !== '') {
                        festivalSocialsHTML += `
                            <div class="festival-social twitter">
                                <a href="https://www.twitter.com/${selectedFestival.twitter}" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-twitter-x" viewBox="0 0 16 16">
                                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                                    </svg>
                                </a>
                            </div>
                        `;
                    }
                    if (selectedFestival.tiktok !== '') {
                        festivalSocialsHTML += `
                            <div class="festival-social tiktok">
                                <a href="https://www.tiktok.com/@${selectedFestival.tiktok}" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-tiktok" viewBox="0 0 16 16">
                                        <path xmlns="http://www.w3.org/2000/svg" d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z"/>
                                    </svg>
                                </a>
                            </div>
                        `;
                    }
                    if (selectedFestival.youtube !== '') {
                        festivalSocialsHTML += `
                            <div class="festival-social youtube">
                                <a href="${selectedFestival.youtube}" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-youtube" viewBox="0 0 16 16">
                                        <path xmlns="http://www.w3.org/2000/svg" d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"/>
                                    </svg>
                                </a>
                            </div>
                        `;
                    }
                    festivalSocialsHTML += '</div>';

                    // IMAGE
                    const imagePath =
                        festivalData.editionName !== ''
                            ? `/assets/img/festivals/${slugify(festivalData.name)}-${festivalData.year}_${slugify(festivalData.editionName)}.jpg`
                            : `/assets/img/festivals/${slugify(festivalData.name)}-${festivalData.year}.jpg`;

                    // DATE
                    let dates = '';
                    if (dayjs(festivalData.start_datetime).format('MM') !== dayjs(festivalData.end_datetime).format('MM')) {
                        dates = `${dayjs(festivalData.start_datetime).format('DD')} ${dayjs(festivalData.start_datetime).format('MMM')} - ${dayjs(festivalData.end_datetime).format('DD')} ${dayjs(
                            festivalData.end_datetime
                        ).format('MMM')} ${festivalData.year}`;
                    } else {
                        dates = `${dayjs(festivalData.start_datetime).format('DD')}-${dayjs(festivalData.end_datetime).format('DD')} ${dayjs(festivalData.end_datetime).format('MMM')} ${
                            festivalData.year
                        }`;
                    }

                    // FESTIVAL GENRES STATS
                    let festivalGenresStatsHTML = '';
                    for (let genreStat in selectedFestival.genresStats) {
                        if (selectedFestival.genresStats[genreStat].percent !== 0) {
                            festivalGenresStatsHTML += `
                                <div class="festival-genres-stats">
                                    <div class="name">
                                        ${genreStat}
                                    </div>
                                </div>
                            `;
                        }
                    }

                    // CIRCULAR
                    const radius = 190 / 2 - 10;
                    const circumference = 3.14 * radius * 2;
                    const strokeDashOffsetPercent = Math.round(circumference * ((100 - selectedFestival.matchingScores.percent) / 100)) + 'px';
                    const strokeDashOffsetGenre = Math.round(circumference * ((100 - selectedFestival.matchingScores.percentArtistLike) / 100)) + 'px';
                    const strokeDashOffsetRelatedGenre = Math.round(circumference * ((100 - selectedFestival.matchingScores.percentTrackLike) / 100)) + 'px';
                    const strokeDashOffsetSubscribe = Math.round(circumference * ((100 - selectedFestival.matchingScores.percentSubscribe) / 100)) + 'px';
                    let textX = 30;
                    if (selectedFestival.matchingScores.percent < 100) {
                        textX = 41;
                    }
                    let userDetailedStats: any = {};
                    userDetailedStats.percent = selectedFestival.matchingScores.percent;
                    userDetailedStats.percentArtistLike = selectedFestival.matchingScores.percentArtistLike;
                    userDetailedStats.percentTrackLike = selectedFestival.matchingScores.percentTrackLike;
                    userDetailedStats.percentSubscribe = selectedFestival.matchingScores.percentSubscribe;
                    userDetailedStats = Object.fromEntries(Object.entries(userDetailedStats).sort(([, a]: any, [, b]: any) => b - a));

                    console.log(userDetailedStats);

                    let circlesHTML = '';
                    let userStatsLegendHTML = '';

                    let maxStatIsNotPercent = 0;
                    for (let stat in userDetailedStats) {
                        if (stat !== 'percent' && userDetailedStats[stat] > maxStatIsNotPercent) {
                            maxStatIsNotPercent = userDetailedStats[stat];
                        }
                    }
                    // console.log(enhancedFestival.name, userDetailedStats.percent, maxStatIsNotPercent, userDetailedStats);
                    const offsetForStats = userDetailedStats.percent - maxStatIsNotPercent;

                    // https://github.com/nikitahl/svg-circle-progress-generator

                    for (let stat in userDetailedStats) {
                        let color = '';
                        let legendText = '';
                        switch (stat) {
                            case 'percent':
                                color = '#079992';
                                break;
                            case 'percentArtistLike':
                                color = '#38ada9';
                                legendText = i18next.t('legends_surely_like');
                                break;
                            case 'percentTrackLike':
                                color = '#78e08f';
                                legendText = i18next.t('legends_may_like');
                                break;
                            case 'percentSubscribe':
                                color = '#b8e994';
                                legendText = i18next.t('legends_follow');
                                break;

                            default:
                                break;
                        }
                        if (stat !== 'percent' && userDetailedStats[stat] !== 0) {
                            const finalValue = userDetailedStats[stat] + offsetForStats;
                            let offset = Math.round(circumference * ((100 - finalValue) / 100)) + 'px';
                            circlesHTML += `
                                    <circle r="85" cx="85" cy="85" stroke="${color}" stroke-width="30" stroke-linecap="round" stroke-dashoffset="${offset}" fill="transparent" stroke-dasharray="533.8000000000001px"></circle>
                                `;
                        }

                        if (stat !== 'percent' && userDetailedStats[stat] !== 0) {
                            userStatsLegendHTML += `
                                <div class="user-stats-legend-item" data-stat="${stat}">
                                    <div class="user-stats-legend-item_color" style="background-color: ${color}">${userDetailedStats[stat]}%</div>
                                    <span>${legendText}</span>
                                </div>
                            `;
                        }
                    }

                    // provider-copyright-message
                    let providerCopyrightMessage = '';
                    switch (InternalDataManager.selectedProvider) {
                        case PROVIDERS.SPOTIFY:
                            providerCopyrightMessage = i18next.t('metadatas_from_spotify');
                            break;
                        case PROVIDERS.DEEZER:
                            providerCopyrightMessage = i18next.t('metadatas_from_deezer');
                            break;
                        case PROVIDERS.APPLE_MUSIC:
                            providerCopyrightMessage = i18next.t('metadatas_from_applemusic');
                            break;

                        default:
                            break;
                    }

                    if (this.$modal) {
                        const $body = this.$modal.querySelector('.modal-body');
                        if ($body) {
                            $body.innerHTML = `
                            <div class="festival-pic-container" style="background: linear-gradient(0deg, #100a19 0%, ${selectedFestival.dominantPosterColor} 70%);">
                                <picture>
                                    <source srcset="${imagePath}.webp" type="image/webp">
                                    <source srcset="${imagePath}" type="image/jpeg"> 
                                    <img src="${imagePath}" alt="${selectedFestival.name}" title="${selectedFestival.name}" loading="lazy"/>
                                </picture>
                                <div class="main-datas">
                                    <div>${festivalSocialsHTML}</div>
                                    <span class="name">${selectedFestival.name}</span>
                                </div>
                            </div>
                            <div class="main-content">
                                <div class="main-datas">
                                    <div class="date">
                                        <span>
                                            ${dates}
                                        </span>
                                    </div>
                                    <div class="venue">
                                        <span>${selectedFestival.city.name}, ${selectedFestival.city.country.name}</span>
                                    </div>
                                    <div class="festivals-genres-stats">
                                        ${festivalGenresStatsHTML}
                                    </div>
                                </div>
                                <ul class="nav nav-tabs justify-content-center" id="statistics-tabs" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="your-statistics-tab" data-bs-toggle="tab" data-bs-target="#your-statistics-tab-pane" type="button" role="tab" aria-controls="your-statistics-tab-pane" aria-selected="true">
                                            ${i18next.t('your_statistics')}
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="statistics-tab" data-bs-toggle="tab" data-bs-target="#festival-statistics-tab-pane" type="button" role="tab" aria-controls="festival-statistics-tab-pane" aria-selected="false">
                                            ${i18next.t('festival_statistics')}
                                        </button>
                                    </li>
                                </ul>
                                <div class="tab-content" id="statistics-tab-content">
                                    <div class="tab-pane fade show active" id="your-statistics-tab-pane" role="tabpanel" aria-labelledby="results-tab" tabindex="0">
                                        <div class="user-stats">
                                            <svg width="150" height="150" viewBox="-21.25 -21.25 212.5 212.5" version="1.1" xmlns="http://www.w3.org/2000/svg" style="transform:rotate(-90deg)">
                                                <circle r="85" cx="85" cy="85" fill="transparent" stroke="#272529" stroke-width="30" stroke-dasharray="533.8000000000001px" stroke-dashoffset="0"></circle>
                                                ${circlesHTML}
                                                <text x="${textX}px" y="115px" fill="#fff" font-size="45px" font-weight="bold" style="transform:rotate(90deg) translate(0px, -186px)">${
                                selectedFestival.matchingScores.percent
                            }%</text>
                                            </svg>
                                            <div class="legend">
                                                <div class="user-stats-legend">
                                                    ${userStatsLegendHTML}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="festival-statistics-tab-pane" role="tabpanel" aria-labelledby="festival-statistics-tab" tabindex="0">
                                        <div id="festival-statistics-chart"></div>
                                    </div>
                                </div>
                                <h1>
                                    ${i18next.t('lineup')}
                                </h1>
                                <div>
                                    <div class="provider-copyright-message">
                                        <div class="">${providerCopyrightMessage}</div>
                                    </div>
                                    <div class="filters-matches">
                                        <div class="d-flex justify-content-center">
                                            <div class="btn-group">
                                                <button class="btn btn-green" data-action="show-matches">${i18next.t('filters_matches_only')}</button>
                                                <button class="btn btn-green active" data-action="show-all-artists">${i18next.t('filters_matches_artists')}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="filters-and-sorts">
                                        <div class="filters-dates"></div>
                                        <div class="filters-venues"></div>
                                        <div class="sorting">
                                            <button class="match-sort active" data-order="more">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1"/>
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5"/>
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart-half" viewBox="0 0 16 16">
                                                    <path d="M8 2.748v11.047c3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15"/>
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                                                </svg>
                                            </button>
                                            <button class="alpha-sort" data-order="asc">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1"/>
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5"/>
                                                </svg>
                                                <span>A-Z</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                                                </svg>
                                            </button>
                                            <button class="time-sort d-none"  data-order="inc">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1"/>
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5"/>
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                                                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
                                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="artists_list"></div>
                                </div>
                            </div>
                        `;
                            this.drawFestivalStatisticsChart();

                            this.$filtersMatchesPanel = document.querySelector('.festival-modal .filters-matches');

                            if (this.$filtersMatchesPanel) {
                                const $actionsButtons = this.$filtersMatchesPanel.querySelectorAll('.btn');
                                if ($actionsButtons.length > 0) {
                                    $actionsButtons.forEach(($actionsButton: any) => {
                                        $actionsButton.addEventListener('click', this.handleFilterMatches.bind(this));
                                    });
                                }
                            }

                            this.$filtersDatesPanel = document.querySelector('.festival-modal .filters-dates');
                            this.$filtersVenuesPanel = document.querySelector('.festival-modal .filters-venues');
                            this.$alphaSort = document.querySelector('.festival-modal .alpha-sort');
                            if (this.$alphaSort) {
                                this.$alphaSort.addEventListener('click', this.sortArtistsByName.bind(this));
                            }
                            this.$matchSort = document.querySelector('.festival-modal .match-sort');
                            if (this.$matchSort) {
                                this.$matchSort.addEventListener('click', this.sortArtistsByMatch.bind(this));
                            }
                            this.$timeSort = document.querySelector('.festival-modal .time-sort');
                            this.$artistsList = document.querySelector('.festival-modal .artists_list');

                            if (this.$artistsList) {
                                console.log(UserManager.userGenresWeightedSorted);

                                let shows = InternalDataManager.selectedFestivalData.shows;

                                console.log(shows);

                                // default sort by match
                                //shows = shows.sort((showA, showB) => showA.artist.name.localeCompare(showB.artist.name));

                                // calculate match
                                shows = shows.map((show: any) => {
                                    const artistOfFestival = show.artist;

                                    show.match = 0;

                                    let hasMatchArtistBySubscribe = false;

                                    if (artistOfFestival.spotifyGenres.length > 0 || artistOfFestival.spotifyRelatedGenres.length > 0) {
                                        let artistProviderId = '';

                                        if (artistOfFestival.spotifyUrl !== '') {
                                            // && artist.applemusicUrl !== '' && artist.deezerUrl !== '') {
                                            //if (selectedProvider === PROVIDERS.SPOTIFY) {
                                            artistProviderId = artistOfFestival.spotifyUrl
                                                .replace('https://open.spotify.com/artist/', '')
                                                .replace('https://open.spotify.com/intl-fr/artist/', '')
                                                .replace('https://api.spotify.com/v1/artists/', '');
                                            if (artistProviderId.indexOf('?si=') !== -1) {
                                                artistProviderId = artistProviderId.substring(0, artistProviderId.indexOf('?'));
                                            }
                                            /*} else if (selectedProvider === PROVIDERS.APPLE_MUSIC) {
                                                artistProviderId = artist.applemusicUrl.substr(-(artist.applemusicUrl.length - artist.applemusicUrl.lastIndexOf('/')) + 1);
                                            }*/
                                            hasMatchArtistBySubscribe = userFollowArtistOnSelectedProvider(UserManager.userFollowingArtists, artistProviderId);
                                        }

                                        let artistOfFestivalFinalGenres = [];

                                        if (artistOfFestival.spotifyGenres && artistOfFestival.spotifyGenres.length > 0) {
                                            artistOfFestivalFinalGenres = artistOfFestival.spotifyGenres;
                                        } else if (artistOfFestival.spotifyRelatedGenres && artistOfFestival.spotifyRelatedGenres.length > 0) {
                                            artistOfFestivalFinalGenres = artistOfFestival.spotifyRelatedGenres;
                                        }

                                        artistOfFestivalFinalGenres.forEach((genreArtistOfFestival: any) => {
                                            UserManager.userGenresWeightedSorted.forEach((userGenre: any) => {
                                                if (genreArtistOfFestival.name === userGenre.label) {
                                                    userGenre.artists.forEach((userGenreArtist: any) => {
                                                        if (userGenreArtist.matchingRank === 1) {
                                                            // track like level
                                                            show.match = 1;
                                                        }
                                                    });
                                                    userGenre.artists.forEach((userGenreArtist: any) => {
                                                        if (userGenreArtist.matchingRank === 2) {
                                                            // artist like level
                                                            show.match = 2;
                                                        }
                                                    });
                                                }
                                            });
                                        });

                                        if (hasMatchArtistBySubscribe) {
                                            show.match = 3;
                                        }
                                    } else {
                                        show.match = 0;
                                    }
                                    return show;
                                });

                                shows = shows.sort((showA: any, showB: any) => {
                                    return showB.match - showA.match;
                                });

                                shows.forEach((show: any) => {
                                    const artist = show.artist;
                                    if (artist.spotifyGenres.length > 0 || artist.spotifyRelatedGenres.length > 0) {
                                        let artistProviderId = '';

                                        if (artist.spotifyUrl !== '') {
                                            artistProviderId = artist.spotifyUrl
                                                .replace('https://open.spotify.com/artist/', '')
                                                .replace('https://open.spotify.com/intl-fr/artist/', '')
                                                .replace('https://api.spotify.com/v1/artists/', '');
                                            if (artistProviderId.indexOf('?si=') !== -1) {
                                                artistProviderId = artistProviderId.substring(0, artistProviderId.indexOf('?'));
                                            }
                                        }

                                        const $divArtist = document.createElement('div');
                                        $divArtist.classList.add('artist');
                                        $divArtist.setAttribute('data-name', slugify(artist.name));

                                        if (show.start_datetime) {
                                            const startDateTime = dayjs(show.start_datetime);
                                            InternalDataManager.selectedFestivalDates[startDateTime.format('DD-MM')] = startDateTime.format('ddd DD/MM');
                                            $divArtist.setAttribute('data-date', startDateTime.format('DD-MM'));
                                        }
                                        if (show.venue) {
                                            const venueNameFormated = formatVenueName(show.venue);
                                            InternalDataManager.selectedFestivalVenues[venueNameFormated] = show.venue.name;
                                            $divArtist.setAttribute('data-venue', venueNameFormated);
                                        }

                                        $divArtist.setAttribute('data-match', '0');
                                        if (show.match === 1 || show.match === 2 || show.match === 3) {
                                            $divArtist.classList.add('matched');
                                        }

                                        if (show.match === 1) {
                                            $divArtist.classList.add('matched-by-track-like');
                                            $divArtist.setAttribute('data-match', '1');
                                        }
                                        if (show.match === 2) {
                                            $divArtist.classList.add('matched-by-artist-like');
                                            $divArtist.setAttribute('data-match', '2');
                                        }
                                        if (show.match === 3) {
                                            $divArtist.setAttribute('data-match', '3');
                                            $divArtist.classList.add('matched-by-subscribe');
                                            $divArtist.classList.remove('matched-by-genre');
                                            $divArtist.classList.remove('matched-by-related-genre');
                                        }

                                        const $lineOne = document.createElement('div');
                                        $lineOne.classList.add('line-one');

                                        const $divArtistMainLink = document.createElement('a');

                                        let artistProviderUrl = '';

                                        if (artist.spotifyUrl !== '' && artist.applemusicUrl !== '' && artist.deezerUrl !== '') {
                                            if (InternalDataManager.selectedProvider === PROVIDERS.SPOTIFY) {
                                                artistProviderUrl = `https://open.spotify.com/artist/${artistProviderId}`;
                                            } else if (InternalDataManager.selectedProvider === PROVIDERS.APPLE_MUSIC) {
                                                artistProviderUrl = artist.applemusicUrl;
                                            } else if (InternalDataManager.selectedProvider === PROVIDERS.DEEZER) {
                                                artistProviderUrl = artist.deezerUrl;
                                            }
                                            $divArtistMainLink.setAttribute('href', artistProviderUrl);
                                            $divArtistMainLink.setAttribute('target', '_blank');
                                        }

                                        // IMAGE
                                        const $divArtistImageContainer = document.createElement('div');
                                        $divArtistImageContainer.classList.add('artist_image');
                                        if (show.match === 1) {
                                            $divArtistImageContainer.classList.add('matched-by-track-like');
                                        }
                                        if (show.match === 2) {
                                            $divArtistImageContainer.classList.add('matched-by-artist-like');
                                        }
                                        if (show.match === 3) {
                                            $divArtistImageContainer.classList.add('matched-by-subscribe');
                                            $divArtistImageContainer.classList.remove('matched-by-genre');
                                            $divArtistImageContainer.classList.remove('matched-by-related-genre');
                                        }

                                        $lineOne.appendChild($divArtistImageContainer);

                                        const $divArtistImage = document.createElement('img');

                                        let artistsImages = [];
                                        if (InternalDataManager.selectedProvider === PROVIDERS.SPOTIFY) {
                                            artistsImages = artist.spotifyImages;
                                        } else if (InternalDataManager.selectedProvider === PROVIDERS.APPLE_MUSIC) {
                                            artistsImages = artist.appleMusicImages;
                                        } else if (InternalDataManager.selectedProvider === PROVIDERS.DEEZER) {
                                            artistsImages = artist.deezerImages;
                                        }
                                        if (artistsImages.length > 0) {
                                            if (InternalDataManager.selectedProvider === PROVIDERS.SPOTIFY) {
                                                $divArtistImage.src = artistsImages[2].url;
                                            } else if (InternalDataManager.selectedProvider === PROVIDERS.APPLE_MUSIC) {
                                                $divArtistImage.src = artistsImages[0].url.replace('{w}', 160).replace('{h}', 160);
                                            } else if (InternalDataManager.selectedProvider === PROVIDERS.DEEZER) {
                                                if (artistsImages.length > 3) {
                                                    const largeImage = artistsImages.find((artistsImage: any) => artistsImage.width === 250);
                                                    $divArtistImage.src = largeImage.url;
                                                }
                                            }
                                        } else {
                                            $divArtistImage.src = '/assets/img/band-avatar.png';
                                        }
                                        $divArtistImage.setAttribute('loading', 'lazy');

                                        $divArtistImageContainer.appendChild($divArtistImage);

                                        const $divArtistAllInfos = document.createElement('div');
                                        $divArtistAllInfos.classList.add('name-and-genre');

                                        // NAME
                                        const $divArtistName = document.createElement('div');
                                        $divArtistName.classList.add('name');
                                        $divArtistName.innerHTML = artist.name;
                                        $divArtistAllInfos.appendChild($divArtistName);

                                        // GENRE
                                        let artistGenres = [];
                                        if (artist.spotifyGenres && artist.spotifyGenres.length > 0) {
                                            artistGenres = artist.spotifyGenres;
                                        }
                                        if (artist.spotifyRelatedGenres && artist.spotifyRelatedGenres.length > 0) {
                                            artistGenres = artist.spotifyRelatedGenres;
                                        }

                                        const $divArtistGenre = document.createElement('div');
                                        $divArtistGenre.classList.add('genre');

                                        if (artistGenres && artistGenres.length > 0) {
                                            const firstGenre = artistGenres[0].name;
                                            $divArtistGenre.innerHTML = firstGenre.charAt(0).toUpperCase() + firstGenre.slice(1);
                                        } else {
                                            $divArtistGenre.innerHTML = '&nbsp;';
                                        }
                                        $divArtistAllInfos.appendChild($divArtistGenre);

                                        const $divArtistDatetimeAndVenue = document.createElement('div');
                                        $divArtistDatetimeAndVenue.classList.add('artist_datetime_and_venue');

                                        const $divArtistDatetime = document.createElement('div');
                                        $divArtistDatetime.classList.add('artist_date');
                                        const startDateTime = dayjs(show.start_datetime);
                                        $divArtistDatetime.innerHTML = startDateTime.format('ddd DD MMMM');
                                        $divArtistDatetimeAndVenue.appendChild($divArtistDatetime);

                                        $divArtistAllInfos.appendChild($divArtistDatetimeAndVenue);

                                        // Festival avec date et heures exactes : Printemps de Bourges
                                        // Festival avec date et heures de départs : Printemps de Bourges

                                        const $divArtistTime = document.createElement('div');
                                        const endDateTime = dayjs(show.end_datetime);

                                        $divArtistTime.classList.add('artist_datetime');
                                        if (show.start_datetime !== show.end_datetime) {
                                            $divArtistTime.innerHTML = startDateTime.format('HH:mm') + ' → ' + endDateTime.format('HH:mm');
                                        } else if (
                                            startDateTime.format('HH:mm') === endDateTime.format('HH:mm') &&
                                            startDateTime.format('HH:mm') !== '00:00' &&
                                            startDateTime.format('HH:mm') !== '01:00' &&
                                            startDateTime.format('HH:mm') !== '02:00'
                                        ) {
                                            $divArtistTime.innerHTML = startDateTime.format('HH:mm');
                                        }
                                        $divArtistDatetimeAndVenue.appendChild($divArtistTime);

                                        if (show.venue) {
                                            const $divArtistVenue = document.createElement('div');
                                            $divArtistVenue.classList.add('artist_venue');
                                            $divArtistVenue.innerHTML = show.venue.name;
                                            $divArtistDatetimeAndVenue.appendChild($divArtistVenue);
                                        }

                                        $lineOne.appendChild($divArtistAllInfos);

                                        $divArtist.appendChild($lineOne);

                                        const $lineTwo = document.createElement('div');
                                        $lineTwo.classList.add('line-two');

                                        const $divLinkProvider = document.createElement('div');
                                        $divLinkProvider.classList.add('go-to-provider');
                                        $divArtistMainLink.innerHTML = `
                                                <img src="/assets/img/${InternalDataManager.selectedProvider}-logo.svg" />
                                                <div>
                                                ${i18next.t('play_on')} ${cleanProviderName(InternalDataManager.selectedProvider)}
                                                </div>
                                        `;
                                        if (artist.spotifyUrl !== '' || artist.applemusicUrl !== '' || artist.deezerUrl !== '') {
                                            $divLinkProvider.appendChild($divArtistMainLink);
                                            $lineTwo.appendChild($divLinkProvider);
                                        }

                                        ////////////////////
                                        // MATCHING BUTTON
                                        ////////////////////

                                        const $divMatchingParent = document.createElement('div');
                                        $divMatchingParent.classList.add('match_infos_parent');

                                        const $divMatching = document.createElement('div');
                                        $divMatching.classList.add('match_infos');
                                        let matchLegend = '';
                                        if (show.match === 1) {
                                            matchLegend = `
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart-fill" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"/>
                                            </svg>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
                                                <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15"/>
                                            </svg>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
                                                <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15"/>
                                            </svg>`;
                                            matchLegend += `<span>${i18next.t('legends_may_like')}</span>`;
                                        }
                                        if (show.match === 2) {
                                            matchLegend = `
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart-fill" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"/>
                                            </svg>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart-fill" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"/>
                                            </svg>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
                                                <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15"/>
                                            </svg>`;
                                            matchLegend += `<span>${i18next.t('legends_surely_like')}</span>`;
                                        }
                                        if (show.match === 3) {
                                            matchLegend = `
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart-fill" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"/>
                                            </svg>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart-fill" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"/>
                                            </svg>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart-fill" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"/>
                                            </svg>`;
                                            matchLegend += `<span>${i18next.t('legends_follow')}</span>`;
                                        }

                                        $divMatching.innerHTML = matchLegend;

                                        if (show.match === 1 || show.match === 2 || show.match === 3) {
                                            $divMatching.classList.add('matched');
                                        }

                                        if (show.match === 1) {
                                            $divMatching.classList.add('matched-by-track-like');
                                        }
                                        if (show.match === 2) {
                                            $divMatching.classList.add('matched-by-artist-like');
                                        }
                                        if (show.match === 3) {
                                            $divMatching.classList.add('matched-by-subscribe');
                                            $divMatching.classList.remove('matched-by-genre');
                                            $divMatching.classList.remove('matched-by-related-genre');
                                        }

                                        if (show.match === 1 || show.match === 2 || show.match === 3) {
                                            $divMatchingParent.appendChild($divMatching);

                                            let $linkedArtists: any = null;

                                            if (show.match === 1 || show.match === 2) {
                                                if (artistGenres && artistGenres.length > 0) {
                                                    const firstGenre = artistGenres[0].name;

                                                    const $matchInfo = document.createElement('div');
                                                    $matchInfo.classList.add('info-button');
                                                    $matchInfo.setAttribute('data-genre', firstGenre);
                                                    $matchInfo.innerHTML = `<svg class="info-button" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                                    </svg>
                                                    <svg class="close-button" xmlns="http://www.w3.org/2000/svg" height="32" width="32" viewBox="0 0 512 512">
                                                        <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.-->
                                                        <path fill="#ffffff" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"></path>
                                                    </svg>
                                                    `;
                                                    $matchInfo.addEventListener('click', this.displayLinkedArtistsByGenre.bind(this));
                                                    $divMatchingParent.appendChild($matchInfo);

                                                    $linkedArtists = document.createElement('div');
                                                    $linkedArtists.classList.add('linked-artists');

                                                    const linkedArtists = UserManager.getUserLinkedArtistsByGenre(firstGenre);

                                                    if (linkedArtists) {
                                                        const $linkedMessage = document.createElement('span');
                                                        $linkedMessage.classList.add('linked-message');
                                                        if (linkedArtists.length === 1) {
                                                            $linkedMessage.innerText = i18next.t('because_singular');
                                                        }
                                                        if (linkedArtists.length > 1) {
                                                            $linkedMessage.innerText = i18next.t('because_plural');
                                                        }
                                                        $linkedArtists.appendChild($linkedMessage);

                                                        const $linkedArtistsContainer = document.createElement('div');
                                                        $linkedArtistsContainer.classList.add('linked-artists-container');
                                                        linkedArtists.forEach((linkedArtist) => {
                                                            const $linkedArtistContainer = document.createElement('div');
                                                            $linkedArtistContainer.classList.add('linked-artist-container');

                                                            const $linkedArtistImage = document.createElement('img');
                                                            $linkedArtistImage.classList.add('linked-artist-image');

                                                            let linkedArtistImages = [];
                                                            if (InternalDataManager.selectedProvider === PROVIDERS.SPOTIFY) {
                                                                linkedArtistImages = linkedArtist.images;
                                                            } else if (InternalDataManager.selectedProvider === PROVIDERS.APPLE_MUSIC) {
                                                                linkedArtistImages = linkedArtist.images;
                                                            } else if (InternalDataManager.selectedProvider === PROVIDERS.DEEZER) {
                                                                linkedArtistImages = linkedArtist.images;
                                                            }
                                                            if (linkedArtistImages && linkedArtistImages.length > 0) {
                                                                if (InternalDataManager.selectedProvider === PROVIDERS.SPOTIFY) {
                                                                    $linkedArtistImage.src = linkedArtistImages[2].url;
                                                                } else if (InternalDataManager.selectedProvider === PROVIDERS.APPLE_MUSIC) {
                                                                    $linkedArtistImage.src = linkedArtistImages[0].url.replace('{w}', 160).replace('{h}', 160);
                                                                } else if (InternalDataManager.selectedProvider === PROVIDERS.DEEZER) {
                                                                    if (linkedArtistImages.length > 1) {
                                                                        const largeImage = linkedArtistImages.find((artistsImage: any) => artistsImage.width > 100);
                                                                        if (largeImage) {
                                                                            $linkedArtistImage.src = largeImage.url;
                                                                        }
                                                                    }
                                                                }
                                                            } else {
                                                                $linkedArtistImage.src = '/assets/img/band-avatar.png';
                                                            }
                                                            $linkedArtistImage.setAttribute('loading', 'lazy');

                                                            $linkedArtistContainer.appendChild($linkedArtistImage);

                                                            const $linkedArtistName = document.createElement('div');
                                                            $linkedArtistName.classList.add('linked-artist-name');
                                                            $linkedArtistName.innerText = linkedArtist.name;

                                                            $linkedArtistContainer.appendChild($linkedArtistName);

                                                            $linkedArtistsContainer.appendChild($linkedArtistContainer);
                                                        });
                                                        $linkedArtists.appendChild($linkedArtistsContainer);
                                                    } else {
                                                        $matchInfo.style.display = 'none';
                                                    }
                                                }
                                            }

                                            $lineTwo.appendChild($divMatchingParent);

                                            if ($linkedArtists) {
                                                $lineTwo.appendChild($linkedArtists);
                                            }
                                        }

                                        $divArtist.appendChild($lineTwo);

                                        this.$artistsList.appendChild($divArtist);
                                    } else {
                                        const $divArtist = document.createElement('div');
                                        $divArtist.classList.add('artist', 'unknown');
                                        $divArtist.setAttribute('data-name', slugify(artist.name));
                                        $divArtist.setAttribute('data-match', '0');

                                        if (show.start_datetime) {
                                            const startDateTime = dayjs(show.start_datetime);
                                            InternalDataManager.selectedFestivalDates[startDateTime.format('DD-MM')] = startDateTime.format('ddd. DD/MM');
                                            $divArtist.setAttribute('data-date', startDateTime.format('DD-MM'));
                                        }
                                        if (show.venue) {
                                            const venueNameFormated = formatVenueName(show.venue);
                                            InternalDataManager.selectedFestivalVenues[venueNameFormated] = show.venue.name;
                                            $divArtist.setAttribute('data-venue', venueNameFormated);
                                        }
                                        this.$artistsList.appendChild($divArtist);

                                        const $divArtistImageContainer = document.createElement('div');
                                        $divArtistImageContainer.classList.add('artist_image');

                                        const $divArtistImage = document.createElement('img');
                                        $divArtistImage.src = '/assets/img/band-avatar.png';
                                        $divArtistImage.setAttribute('loading', 'lazy');

                                        $divArtistImageContainer.appendChild($divArtistImage);

                                        $divArtist.appendChild($divArtistImageContainer);

                                        const $divArtistName = document.createElement('div');
                                        $divArtistName.classList.add('name');
                                        $divArtistName.innerHTML = artist.name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, (letter: any) => letter.toUpperCase());
                                        $divArtist.appendChild($divArtistName);

                                        const $divArtistGenre = document.createElement('div');
                                        $divArtistGenre.classList.add('genre');
                                        $divArtistGenre.innerHTML = 'Unknown genre';
                                        $divArtist.appendChild($divArtistGenre);

                                        const $divArtistDatetimeAndVenue = document.createElement('div');
                                        $divArtistDatetimeAndVenue.classList.add('artist_datetime_and_venue');

                                        const $divArtistDatetime = document.createElement('div');
                                        $divArtistDatetime.classList.add('artist_date');
                                        const startDateTime = dayjs(show.start_datetime);
                                        $divArtistDatetime.innerHTML = startDateTime.format('ddd DD MMMM');
                                        $divArtistDatetimeAndVenue.appendChild($divArtistDatetime);

                                        $divArtist.appendChild($divArtistDatetimeAndVenue);

                                        const $divArtistTime = document.createElement('div');
                                        const endDateTime = dayjs(show.end_datetime);

                                        $divArtistTime.classList.add('artist_datetime');
                                        if (show.start_datetime !== show.end_datetime) {
                                            $divArtistTime.innerHTML = startDateTime.format('HH:mm') + ' → ' + endDateTime.format('HH:mm');
                                        } else if (
                                            startDateTime.format('HH:mm') === endDateTime.format('HH:mm') &&
                                            startDateTime.format('HH:mm') !== '00:00' &&
                                            startDateTime.format('HH:mm') !== '01:00' &&
                                            startDateTime.format('HH:mm') !== '02:00'
                                        ) {
                                            $divArtistTime.innerHTML = startDateTime.format('HH:mm');
                                        }
                                        $divArtistDatetimeAndVenue.appendChild($divArtistTime);

                                        if (show.venue) {
                                            const $divArtistVenue = document.createElement('div');
                                            $divArtistVenue.classList.add('artist_venue');
                                            $divArtistVenue.innerHTML = show.venue.name;
                                            $divArtistDatetimeAndVenue.appendChild($divArtistVenue);
                                        }
                                    }
                                });
                            }

                            if (Object.keys(InternalDataManager.selectedFestivalDates).length <= 1) {
                                this.$filtersDatesPanel.classList.add('d-none');
                            } else {
                                this.populateFiltersDates(InternalDataManager.selectedFestivalDates);
                            }

                            if (Object.keys(InternalDataManager.selectedFestivalVenues).length <= 1) {
                                this.$filtersVenuesPanel.classList.add('d-none');
                            } else {
                                this.populateFiltersVenues(InternalDataManager.selectedFestivalVenues);
                            }

                            this.modal.show();
                        }
                    }
                }
            }
        }
    }

    clearAllSorts() {
        if (this.$alphaSort) {
            this.$alphaSort.classList.remove('active');
        }
        if (this.$matchSort) {
            this.$matchSort.classList.remove('active');
        }
        if (this.$timeSort) {
            this.$timeSort.classList.remove('active');
        }
    }

    sortArtistsByMatch(ev) {
        const $target = ev.currentTarget;
        const $upArrow = ev.currentTarget.querySelector('.bi-arrow-up');
        const $downArrow = ev.currentTarget.querySelector('.bi-arrow-down');
        let previousStateActive = $target.classList.contains('active');
        if ($target) {
            if (this.$alphaSort) {
                this.$alphaSort.classList.remove('active');
            }
            if (this.$timeSort) {
                this.$timeSort.classList.remove('active');
            }
            if (!previousStateActive) {
                $target.classList.add('active');
            }
            const order = $target.getAttribute('data-order');
            const artistsToOrder = [...this.$modal.querySelectorAll('.artists_list .artist')];
            const parentElement = artistsToOrder[0].parentNode;
            if (order === 'more') {
                artistsToOrder
                    .sort(function (a, b) {
                        a = a.getAttribute('data-match');
                        b = b.getAttribute('data-match');
                        return b.localeCompare(a);
                    })
                    .forEach(function (a) {
                        parentElement.appendChild(a);
                    });
                if ($upArrow) {
                    $upArrow.style.display = 'none';
                }
                if ($downArrow) {
                    $downArrow.style.display = 'block';
                }
                $target.setAttribute('data-order', 'less');
            } else {
                artistsToOrder
                    .sort(function (a, b) {
                        a = a.getAttribute('data-match');
                        b = b.getAttribute('data-match');
                        return a.localeCompare(b);
                    })
                    .forEach(function (a) {
                        parentElement.appendChild(a);
                    });
                if ($upArrow) {
                    $upArrow.style.display = 'block';
                }
                if ($downArrow) {
                    $downArrow.style.display = 'none';
                }
                $target.setAttribute('data-order', 'more');
            }
        }
    }

    sortArtistsByName(ev) {
        const $target = ev.currentTarget;
        const $upArrow = ev.currentTarget.querySelector('.bi-arrow-up');
        const $downArrow = ev.currentTarget.querySelector('.bi-arrow-down');
        let previousStateActive = $target.classList.contains('active');
        if ($target) {
            if (this.$matchSort) {
                this.$matchSort.classList.remove('active');
            }
            if (this.$timeSort) {
                this.$timeSort.classList.remove('active');
            }
            if (!previousStateActive) {
                $target.classList.add('active');
            }
            const order = $target.getAttribute('data-order');
            const artistsToOrder = [...this.$modal.querySelectorAll('.artists_list .artist')];
            const parentElement = artistsToOrder[0].parentNode;
            if (order === 'asc') {
                artistsToOrder
                    .sort(function (a, b) {
                        a = a.getAttribute('data-name');
                        b = b.getAttribute('data-name');
                        return a.localeCompare(b);
                    })
                    .forEach(function (a) {
                        parentElement.appendChild(a);
                    });
                if ($upArrow) {
                    $upArrow.style.display = 'none';
                }
                if ($downArrow) {
                    $downArrow.style.display = 'block';
                }
                $target.setAttribute('data-order', 'desc');
            } else {
                artistsToOrder
                    .sort(function (a, b) {
                        a = a.getAttribute('data-name');
                        b = b.getAttribute('data-name');
                        return b.localeCompare(a);
                    })
                    .forEach(function (a) {
                        parentElement.appendChild(a);
                    });
                if ($upArrow) {
                    $upArrow.style.display = 'block';
                }
                if ($downArrow) {
                    $downArrow.style.display = 'none';
                }
                $target.setAttribute('data-order', 'asc');
            }
        }
    }

    closeAllLinkedArtists() {
        const $details = this.$modal.querySelectorAll('.linked-artists');
        $details.forEach(($detail: any) => {
            $detail.style.display = 'none';
        });
    }

    displayLinkedArtistsByGenre(ev: any) {
        const $target = ev.currentTarget;
        if ($target) {
            const $matchInfosParent = $target.parentElement;

            const $infoButton = $target.querySelector('.info-button');
            const $closeButton = $target.querySelector('.close-button');

            if ($matchInfosParent) {
                const $parentLineTwo = $matchInfosParent.parentElement;
                if ($parentLineTwo) {
                    const $linkedArtists = $parentLineTwo.querySelector('.linked-artists');
                    if ($linkedArtists) {
                        if (getComputedStyle($linkedArtists)) {
                            let display = getComputedStyle($linkedArtists)['display'];
                            if (display && display === 'none') {
                                // Close others detail infos
                                this.closeAllLinkedArtists();
                                $linkedArtists.style.display = 'flex';
                                if ($infoButton) {
                                    $infoButton.style.display = 'none';
                                }
                                if ($closeButton) {
                                    $closeButton.style.display = 'block';
                                }
                            } else {
                                $linkedArtists.style.display = 'none';
                                if ($infoButton) {
                                    $infoButton.style.display = 'block';
                                }
                                if ($closeButton) {
                                    $closeButton.style.display = 'none';
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    handleFilterMatches(ev: any) {
        const $target = ev.currentTarget;
        if ($target) {
            if (this.$filtersMatchesPanel) {
                const $actionsButtons = this.$filtersMatchesPanel.querySelectorAll('.btn');
                if ($actionsButtons.length > 0) {
                    $actionsButtons.forEach(($actionsButton: any) => {
                        $actionsButton.classList.remove('active');
                    });
                }
                $target.classList.add('active');
            }

            const action = $target.getAttribute('data-action');
            switch (action) {
                case 'show-matches':
                    this.displayMatches = true;
                    if (this.$artistsList) {
                        this.$artistsList.classList.add('show-matches');
                        this.$artistsList.classList.remove('show-all-artists');
                    }
                    break;
                case 'show-all-artists':
                    this.displayMatches = false;
                    if (this.$artistsList) {
                        this.$artistsList.classList.remove('show-matches');
                        this.$artistsList.classList.add('show-all-artists');
                    }
                    break;

                default:
                    break;
            }
        }
    }

    clearFiltersStates(panel: any) {
        const $filters = panel.querySelectorAll('.filter');
        $filters.forEach(($filter: any) => {
            $filter.classList.remove('active');
        });
    }

    activateAllVenuesFilter() {
        const $el = this.$filtersVenuesPanel.querySelector('.all');
        if ($el) {
            $el.classList.add('active');
        }
    }

    onClickFilterVenues(ev: any) {
        this.clearFiltersStates(this.$filtersVenuesPanel);
        const $filter = ev.currentTarget;
        if ($filter) {
            $filter.classList.add('active');
            const filterData = $filter.getAttribute('data-venue');
            const $artists = this.$artistsList.querySelectorAll('.artist');
            if (filterData) {
                if (filterData === 'all') {
                    if (this.selectedFilterDate === 'a-z') {
                        $artists.forEach(($artist: any) => {
                            $artist.classList.remove('d-none');
                        });
                    } else {
                        $artists.forEach(($artist: any) => {
                            const dataDate = $artist.getAttribute('data-date');
                            if (!$artist.classList.contains('unknown')) {
                                if (dataDate && dataDate === this.selectedFilterDate) {
                                    $artist.classList.remove('d-none');
                                }
                            } else {
                                $artist.classList.add('d-none');
                            }
                        });
                    }
                } else {
                    if (this.selectedFilterDate === 'a-z') {
                        $artists.forEach(($artist: any) => {
                            const dataVenue = $artist.getAttribute('data-venue');
                            if (!$artist.classList.contains('unknown')) {
                                $artist.classList.remove('d-none');
                                if (dataVenue && dataVenue !== filterData) {
                                    $artist.classList.add('d-none');
                                }
                            } else {
                                $artist.classList.add('d-none');
                            }
                        });
                    } else {
                        $artists.forEach(($artist: any) => {
                            const dataDate = $artist.getAttribute('data-date');
                            const dataVenue = $artist.getAttribute('data-venue');
                            if (!$artist.classList.contains('unknown')) {
                                if (dataDate && dataDate !== this.selectedFilterDate) {
                                    $artist.classList.add('d-none');
                                } else {
                                    $artist.classList.remove('d-none');
                                }
                                if (dataVenue && dataVenue !== filterData) {
                                    $artist.classList.add('d-none');
                                }
                            }
                        });
                    }
                }
            }
        }
        // this.updateStatistics();
    }

    populateFiltersVenues = (venues: any) => {
        this.$filtersVenuesPanel.innerHTML = '';

        const $allFilter = document.createElement('div');
        $allFilter.classList.add('filter', 'all', 'active');
        $allFilter.setAttribute('data-venue', 'all');
        if (getBrowserLanguage() === 'fr') {
            $allFilter.innerHTML = 'TOUT';
        } else {
            $allFilter.innerHTML = 'ALL';
        }
        $allFilter.addEventListener('click', this.onClickFilterVenues.bind(this));
        this.$filtersVenuesPanel.appendChild($allFilter);

        const sortedVenues = Object.keys(venues)
            .sort()
            .reduce((acc, curr) => ({ ...acc, [curr]: venues[curr] }), {});

        for (let venue in sortedVenues) {
            const $filter = document.createElement('div');
            const venueName = venues[venue];
            $filter.classList.add('filter');
            $filter.setAttribute('data-venue', venue);
            $filter.innerHTML = venueName;
            $filter.addEventListener('click', this.onClickFilterVenues.bind(this));
            if (venueName.length > 12) {
                $filter.style.minWidth = '120px';
            }
            if (venueName.length > 17) {
                $filter.style.minWidth = '145px';
            }
            if (venueName.length > 19) {
                $filter.style.minWidth = '170px';
            }
            if (venueName.length > 25) {
                $filter.style.minWidth = '215px';
            }
            this.$filtersVenuesPanel.appendChild($filter);
        }
    };

    onClickFilterDates(ev: any) {
        this.clearFiltersStates(this.$filtersDatesPanel);

        this.clearFiltersStates(this.$filtersVenuesPanel);
        this.activateAllVenuesFilter();

        const $filter = ev.currentTarget;
        if ($filter) {
            $filter.classList.add('active');
            const filterData = $filter.getAttribute('data-filter');
            const $artists = this.$artistsList.querySelectorAll('.artist');
            if (filterData) {
                this.selectedFilterDate = filterData;
                if (filterData === 'a-z') {
                    $artists.forEach(($artist: any) => {
                        $artist.classList.remove('d-none');
                    });
                    this.populateFiltersVenues(InternalDataManager.selectedFestivalVenues);
                } else {
                    $artists.forEach(($artist: any) => {
                        const dataDate = $artist.getAttribute('data-date');
                        if (!$artist.classList.contains('unknown')) {
                            $artist.classList.remove('d-none');
                            if (dataDate && dataDate !== filterData) {
                                $artist.classList.add('d-none');
                            }
                        } else {
                            $artist.classList.add('d-none');
                        }
                    });
                    const venuesDisplayedArtists = this.getVenuesFromDisplayedArtists();
                    if (venuesDisplayedArtists) {
                        this.populateFiltersVenues(venuesDisplayedArtists);
                    }
                }
            }
        }

        // this.updateStatistics();
    }

    getVenuesFromDisplayedArtists() {
        let artistsVisibleRawVenues = new Set<string>();
        const $artistsVisible = this.$artistsList.querySelectorAll('.artist:not(.d-none):not(.unknown)');
        $artistsVisible.forEach(($artistVisible: any) => {
            const artistVenue = $artistVisible.getAttribute('data-venue');
            artistsVisibleRawVenues.add(artistVenue);
        });
        let artistsVisibleVenues: any = {};
        for (let venue of artistsVisibleRawVenues) {
            artistsVisibleVenues[venue] = InternalDataManager.selectedFestivalVenues[venue];
        }
        return artistsVisibleVenues;
    }

    populateFiltersDates(dates: any) {
        this.$filtersDatesPanel.innerHTML = '';

        const $alphaFilter = document.createElement('div');
        $alphaFilter.classList.add('filter', 'alphabetically', 'active');
        $alphaFilter.setAttribute('data-filter', 'a-z');
        $alphaFilter.innerHTML = 'A-Z';
        $alphaFilter.addEventListener('click', this.onClickFilterDates.bind(this));
        this.$filtersDatesPanel.appendChild($alphaFilter);

        const sortedDates = Object.keys(dates)
            .sort()
            .reduce((acc, curr) => ({ ...acc, [curr]: dates[curr] }), {});

        for (let date in sortedDates) {
            const $filter = document.createElement('div');
            $filter.classList.add('filter');
            $filter.setAttribute('data-filter', date);
            $filter.innerHTML = dates[date];
            $filter.addEventListener('click', this.onClickFilterDates.bind(this));
            this.$filtersDatesPanel.appendChild($filter);
        }
    }

    drawFestivalStatisticsChart() {
        if (InternalDataManager.selectedFestivalData.hasOwnProperty('genresStats')) {
            // Colors
            const gradientArray = new Gradient().setColorGradient('#3F2CAF', 'e9446a').setMidpoint(Object.keys(InternalDataManager.selectedFestivalData.genresStats).length).getColors();

            // Sort
            const copy = Object.assign({}, InternalDataManager.selectedFestivalData.genresStats);
            const sorted = Object.entries(copy)
                .sort(([, a]: any, [, b]: any) => b.percent - a.percent)
                .reduce(
                    (r, [k, v]) => ({
                        ...r,
                        [k]: v,
                    }),
                    {}
                );

            const $node = document.querySelector('.festival-modal #festival-statistics-chart');
            let chartHTML = '<div class="festival_genres_stats">';

            let minWidth = '40px';

            let genreCount = 0;
            for (let genre in sorted) {
                const percent = InternalDataManager.selectedFestivalData.genresStats[genre].percent;
                if (percent) {
                    if (percent <= 5) {
                        minWidth = '35px';
                    }
                    chartHTML += `
                        <div class="festival_genres_stat">
                            <div class="bar" style="width: ${percent}%; background-color: ${gradientArray[genreCount]}; min-width: ${minWidth};">
                                ${percent}%
                            </div>
                            <div>
                                ${genre}
                            </div>
                        </div>
                    `;
                    genreCount += 1;
                }
            }

            chartHTML += '</div>';

            if ($node) {
                $node.innerHTML = chartHTML;
            }
        }
    }
}

export default new DetailFestivalModalManager();
