import { initDayjsLocales } from './i18n-utils';

import { initializePaddle } from '@paddle/paddle-js';

import { createClient } from '@supabase/supabase-js';
import isEmail from 'validator/lib/isEmail';

import I18nManager from './i18n-manager.js';
import SuggestFestivalManager from './suggest-festival-manager.js';
import SelectFestivalManager from './select-festival-manager.js';
import InternalDataManager from './internal-data-manager.js';
import UserManager from './user-manager.js';
import DetailFestivalModalManager from './detail-festival-modal.js';
import ShareModalManager from './share-manager.js';
import MenuManager from './menu-manager.js';

import { LINEFEST_SELECTED_PROVIDER, MODES, PROVIDERS } from './utils.js';
import { extractAccessTokenFromURLOrLS } from './deezer/index.js';

declare const $: any;
declare const Sentry: any;
declare const Paddle: any;

const REDIRECT_URL = import.meta.env.MODE === 'development' ? 'http://localhost:5173/fr-fr/' : 'https://www.linefest.app';

console.log(import.meta.env.MODE);

let $selectionModeLight: any = null;

let $loginFeedbacks: any = null;
let $signupFeedbacks: any = null;
let $ctaButton: any = null;

declare global {
    interface Window {
        onAmazonLoginReady: any;
        supabase: any;
    }
}
declare const i18next: any;

initDayjsLocales();

const showSelectPanel = () => {
    SelectFestivalManager.showSelectPanel();
    SuggestFestivalManager.hideSuggestPanel();
};

const showSuggestPanel = () => {
    SelectFestivalManager.hideSelectPanel();
    SuggestFestivalManager.showSuggestPanel();
};

const initHomeVideo = () => {
    if (window.innerWidth > 766) {
        const $video = document.querySelector('video');
        if ($video) {
            let sourceWebm = document.createElement('source');
            sourceWebm.src = '/assets/img/dance-video_720.webm';
            sourceWebm.type = 'video/webm';
            $video.appendChild(sourceWebm);

            let sourceMp4 = document.createElement('source');
            sourceMp4.src = '/assets/img/dance-video_720.mp4';
            sourceMp4.type = 'video/mp4';
            $video.appendChild(sourceWebm);
        }
    }
};

const hideCTA = () => {
    const $ctaContainer = document.querySelector('.cta-container') as HTMLElement;
    if ($ctaContainer) {
        $ctaContainer.style.display = 'none';
    }
};

const showCTA = () => {
    const $ctaContainer = document.querySelector('.cta-container') as HTMLElement;
    if ($ctaContainer) {
        $ctaContainer.style.display = 'block';
    }
};

const showEngine = () => {
    const $engineContainer = document.querySelector('.engine') as HTMLElement;
    if ($engineContainer) {
        $engineContainer.classList.remove('d-none');
    }
};

const hideEngine = () => {
    const $engineContainer = document.querySelector('.engine') as HTMLElement;
    if ($engineContainer) {
        $engineContainer.classList.add('d-none');
    }
};

const onEmailInput = (e) => {
    const emailInputValue = e.currentTarget.value;
    if (emailInputValue !== '') {
        if (isEmail(emailInputValue)) {
            $ctaButton.removeAttribute('disabled');
        }
    }
};

/**
 * STEPS
 */

let $steps: any = [];

const switchStep = (e: any) => {
    const $target = e.currentTarget;
    if ($target) {
        $steps.forEach(($step: HTMLElement) => {
            $step.classList.remove('active');
        });
        $target.classList.add('active');
    }
};

const initSteps = () => {
    $steps = [...document.querySelectorAll('.steps .step')];
    $steps.forEach(($step) => {
        $step.addEventListener('click', switchStep);
    });
    if ($steps.length > 0) {
        const $firstDescription = $steps[0].querySelector('.description');
        if ($firstDescription) {
            $firstDescription.innerText = $firstDescription.innerText.replace('_X_', InternalDataManager.festivals.length);
        }
    }
};

const onLoad = async () => {
    console.log('onLoad');

    ('use strict');

    /* --------------------- TO TOP -------------------- */
    const $toTop = document.querySelector('.to-top');

    document.addEventListener('scroll', () => {
        if (document.documentElement.scrollTop > 500) {
            $toTop?.classList.add('active');
        } else {
            $toTop?.classList.remove('active');
        }
    });

    if ($toTop) {
        $toTop.addEventListener('click', () => {
            if (SelectFestivalManager.$matcherPanel) {
                if (SelectFestivalManager.$matcherPanel.classList.contains('d-flex')) {
                    scroll({
                        top: 0,
                        behavior: 'smooth',
                    });
                } else {
                    scroll({
                        top: SelectFestivalManager.$matcherPanel.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 30,
                        behavior: 'smooth',
                    });
                }
            } else {
                scroll({
                    top: 0,
                    behavior: 'smooth',
                });
            }
        });
    }

    await MenuManager.init();

    await I18nManager.init();
    await InternalDataManager.init();
    initSteps();
    await UserManager.init();
    await DetailFestivalModalManager.init();
    await ShareModalManager.init();
    await SuggestFestivalManager.init();
    await SelectFestivalManager.init();

    // Supabase

    let urlSupabase = '';
    // local
    //url = 'http://127.0.0.1:54321';
    // prod
    urlSupabase = 'https://xcrhmalxuytbyrrqkesj.supabase.co';

    let supabaseClient: any = null;

    const initializeSupabase = () => {
        supabaseClient = createClient(
            urlSupabase,
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InhjcmhtYWx4dXl0YnlycnFrZXNqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTU3MTg3MTUsImV4cCI6MjAzMTI5NDcxNX0.esm_--kngHKRBnwjEtjR_qyvd1KOmyflVbQ1w7qrTd0'
        );
        window['supabase'] = supabaseClient;
    };

    // For Deezer, when coming from auth, first extract from url and after start Supabase
    if (InternalDataManager.selectedProvider !== '' && InternalDataManager.selectedProvider === PROVIDERS.DEEZER) {
        await extractAccessTokenFromURLOrLS();
        initializeSupabase();
    } else {
        initializeSupabase();
    }

    supabaseClient.auth.onAuthStateChange((event, session) => {
        console.log(event, session);
        if (event === 'INITIAL_SESSION') {
            // handle initial session
            if (!session) {
                UserManager.showLoginButton();
                UserManager.hideLogoutButton();
            } else {
                UserManager.hideLoginButton();
                UserManager.showLogoutButton();
                hideCTA();
                showEngine();
            }
        } else if (event === 'SIGNED_IN') {
            // handle sign in event
            console.log('SIGNED_IN', session);
            hideCTA();
            UserManager.hideLoginButton();
            UserManager.showLogoutButton();
            showEngine();
            const $festivals = document.getElementById('festivals');
            if ($festivals) {
                if (!DetailFestivalModalManager.modal._isShown) {
                    scroll({
                        top: $festivals.offsetTop,
                        behavior: 'smooth',
                    });
                }
            }
        } else if (event === 'SIGNED_OUT') {
            // handle sign out event
            hideEngine();
            showCTA();
            if (InternalDataManager.selectedMode === MODES.SUGGEST) {
                SuggestFestivalManager.hideSuggestPanelResults();
                SuggestFestivalManager.showSuggestPanelLogins();
            } else {
                SelectFestivalManager.hideSelectPanelResults();
                SelectFestivalManager.showSelectPanelLogins();
            }
        } else if (event === 'PASSWORD_RECOVERY') {
            // handle password recovery event
        } else if (event === 'TOKEN_REFRESHED') {
            // handle token refreshed event
        } else if (event === 'USER_UPDATED') {
            // handle user updated event
            UserManager.hideLoginButton();
            UserManager.showLogoutButton();
            hideCTA();
            showEngine();
        }
    });

    // Modes

    $selectionModeLight = document.querySelector('.selection-mode-light');

    $loginFeedbacks = document.querySelector('.login-feedbacks');
    $signupFeedbacks = document.querySelector('.signup-feedbacks');

    if ($selectionModeLight) {
        const $suggestButton = $selectionModeLight.querySelector('.btn[data-action="suggest-a-festival"]');
        if ($suggestButton) {
            $suggestButton.addEventListener('click', showSuggestPanel);
        }
        const $selectButton = $selectionModeLight.querySelector('.btn[data-action="select-a-festival"]');
        if ($selectButton) {
            $selectButton.addEventListener('click', showSelectPanel);
        }
    }

    if (InternalDataManager.selectedMode !== '') {
        if (InternalDataManager.selectedMode === MODES.SELECT) {
            SelectFestivalManager.initWithLocalSelectedFestival();
        }
        if (InternalDataManager.selectedMode === MODES.SUGGEST) {
            SuggestFestivalManager.initSuggest();
        }
        if (UserManager.userFollowingArtists.length > 0) {
            UserManager.hideLoginButton();
            UserManager.showLogoutButton();
        }
        if (document.querySelector('#festivals')) {
            scroll({
                top: document.querySelector('#festivals').getBoundingClientRect().top - document.body.getBoundingClientRect().top - 30,
                behavior: 'smooth',
            });
        }
    }

    initializePaddle({
        token: import.meta.env.VITE_PADDLE_ENV_TOKEN,
        checkout: {
            settings: {
                displayMode: 'overlay',
                locale: i18next.resolvedLanguage,
            },
        },
        eventCallback: async (data) => {
            console.log(data, data.name);

            switch (data.name) {
                case 'checkout.loaded':
                    console.log('Checkout opened');
                    break;
                case 'checkout.closed':
                    console.log('Checkout closed');
                    $ctaButton.innerHTML = i18next.t('payment_closed');
                    setTimeout(() => {
                        $ctaButton.innerHTML = i18next.t('start_now');
                    }, 3000);
                    break;
                case 'checkout.payment.failed':
                    console.log('Checkout payment.failed');
                    $ctaButton.innerHTML = i18next.t('payment_failed');
                    setTimeout(() => {
                        $ctaButton.innerHTML = i18next.t('start_now');
                    }, 3000);
                    break;
                case 'checkout.customer.created':
                    console.log('Customer created');
                    break;
                case 'checkout.completed':
                    console.log('Checkout completed: ', REDIRECT_URL);
                    const { data, error } = await supabaseClient.auth.signInWithOtp({
                        email: $loginInput.value,
                        options: {
                            emailRedirectTo: REDIRECT_URL,
                        },
                    });
                    if (data) {
                        $ctaButton.style.display = 'none';
                        if ($signupFeedbacks) {
                            $signupFeedbacks.classList.remove('d-none');
                        }
                        $loginInput.style.display = 'none';
                        if (Paddle) {
                            Paddle.Checkout.close();
                        }
                    }
                    break;
                default:
                    console.log(data);
            }
        },
    });

    const $loginInput = document.querySelector('.lifetime-access-panel .login-input') as HTMLInputElement;
    if ($loginInput) {
        $loginInput.addEventListener('input', onEmailInput);
    }

    $ctaButton = document.querySelector('.cta-buttons .btn-buy');
    if ($ctaButton && $loginInput) {
        $ctaButton.addEventListener('click', async () => {
            const dataRequest = {
                email: $loginInput.value,
            };
            if ($loginInput.value !== '') {
                $ctaButton.innerHTML = i18next.t('account_checkup');
                const { data, error } = await supabaseClient.functions.invoke('get-user', {
                    method: 'POST',
                    body: dataRequest,
                });
                if (data && data.data && data.data.length > 0) {
                    const { data, error } = await supabaseClient.auth.signInWithOtp({
                        email: $loginInput.value,
                        options: {
                            emailRedirectTo: REDIRECT_URL,
                            shouldCreateUser: false,
                        },
                    });
                    if (error) {
                        console.log(error);
                    }
                    if (data && !error) {
                        $ctaButton.style.display = 'none';
                        $loginInput.style.display = 'none';
                        if ($loginFeedbacks) {
                            $loginFeedbacks.classList.remove('d-none');
                        }
                    }
                } else {
                    $ctaButton.innerHTML = i18next.t('payment_in_progress');
                    if (Paddle) {
                        if (import.meta.env.MODE === 'development') {
                            Paddle.Environment.set('sandbox');
                        }
                        Paddle.Checkout.open({
                            items: [
                                {
                                    priceId: import.meta.env.VITE_PADDLE_PRICE_ID,
                                    quantity: 1,
                                },
                            ],
                            customer: {
                                email: $loginInput.value,
                            },
                        });
                    }
                }
            }
        });
    }

    initHomeVideo();

    Sentry.init({
        dsn: 'https://6a76686fa16a48d7b1ab2e2fa01f9cc5@app.glitchtip.com/5539',
        tracesSampleRate: 0.01,
    });
};

document.addEventListener('DOMContentLoaded', onLoad);
