import * as bootstrap from 'bootstrap';

import InternalDataManager from './internal-data-manager.js';
import UserManager from './user-manager';

import { imageUrlToBase64, loadSVGImage, slugify } from './utils';
import { SVG_TEMPLATE } from './share-svg-template.js';

let ShareModalManagerInstance: any;

declare const resvg: any;

export class ShareModalManager {
    $modal: any = null;
    modal: any = null;

    $images: any = null;
    $originalSvg: any = null;
    $proxy: any = null;
    $result: any = null;
    resultBlob: any = null;

    $btn: any = null;

    showOnce = false;

    constructor() {
        if (!ShareModalManagerInstance) {
            ShareModalManagerInstance = this;
        }
        return ShareModalManagerInstance;
    }

    init() {
        return new Promise((shareModalInitResolve, shareModalInitReject) => {
            this.$modal = document.getElementById('shareModal');

            if (this.$modal) {
                this.$originalSvg = this.$modal.querySelector('.original-svg');
                this.$images = this.$modal.querySelector('.images');
                this.$proxy = this.$modal.querySelector('.proxy');
                this.$result = this.$modal.querySelector('.result');
                this.$btn = this.$modal.querySelector('.btn');
                this.modal = new bootstrap.Modal(this.$modal);
                this.$modal.addEventListener('hidden.bs.modal', (event: any) => {});
                if (this.$btn) {
                    this.$btn.addEventListener('click', this.share.bind(this));
                }
            }

            shareModalInitResolve(true);
        });
    }

    async share() {
        const imageName = 'my-top-festivals-2024.jpg';
        const filesArray = [
            new File([this.resultBlob], imageName, {
                type: this.resultBlob.type,
                lastModified: new Date().getTime(),
            }),
        ];
        const shareData = {
            files: filesArray,
        };

        try {
            await navigator.share(shareData);
        } catch (error) {
            console.log(error);
            const anchor = document.createElement('a');
            anchor.download = imageName;
            anchor.href = URL.createObjectURL(this.resultBlob);

            anchor.click();

            URL.revokeObjectURL(anchor.href);
        }
    }

    async show() {
        if (this.modal) {
            this.modal.show();

            if (!this.showOnce) {
                this.showOnce = true;
                let firstTop5: any = UserManager.getUserFestivalsMatches();

                if (firstTop5) {
                    try {
                        firstTop5 = JSON.parse(firstTop5);
                        if (firstTop5) {
                            firstTop5 = firstTop5.slice(0, 5);
                            firstTop5 = firstTop5.map((matchingFestival: any) => {
                                return InternalDataManager.getFestivalMainInfosById(matchingFestival.id);
                            });
                            console.log(firstTop5);
                        }
                    } catch (error) {}
                }

                if (this.$originalSvg) {
                    let imageWidth = 0;
                    let imageHeight = 0;
                    if (window.innerWidth > 500) {
                        imageWidth = 500;
                        imageHeight = 500;
                        this.$images.style.width = imageWidth + 'px';
                        this.$images.style.height = imageHeight + 'px';
                    } else {
                        imageWidth = window.innerWidth;
                        this.$images.style.height = imageWidth + 'px';
                        this.$images.style.width = '100%';
                    }

                    let raw_template = SVG_TEMPLATE;

                    //this.$proxy.innerHTML = templateSvg;

                    let festivalData = firstTop5[0];
                    let imagePath =
                        festivalData.editionName !== ''
                            ? `${slugify(festivalData.name)}-${festivalData.year}_${slugify(festivalData.editionName)}.jpg`
                            : `${slugify(festivalData.name)}-${festivalData.year}.jpg`;

                    // load first image
                    const firstImageBase64 = await imageUrlToBase64(`/assets/img/festivals/${imagePath}`);
                    raw_template = raw_template.replace('_url_image_1', firstImageBase64);

                    // update second image
                    festivalData = firstTop5[1];
                    imagePath =
                        festivalData.editionName !== ''
                            ? `${slugify(festivalData.name)}-${festivalData.year}_${slugify(festivalData.editionName)}.jpg`
                            : `${slugify(festivalData.name)}-${festivalData.year}.jpg`;
                    const secondImageBase64 = await imageUrlToBase64(`/assets/img/festivals/${imagePath}`);
                    raw_template = raw_template.replace('_url_image_2', secondImageBase64);

                    // update third image
                    festivalData = firstTop5[2];
                    imagePath =
                        festivalData.editionName !== ''
                            ? `${slugify(festivalData.name)}-${festivalData.year}_${slugify(festivalData.editionName)}.jpg`
                            : `${slugify(festivalData.name)}-${festivalData.year}.jpg`;
                    const thirdImageBase64 = await imageUrlToBase64(`/assets/img/festivals/${imagePath}`);
                    raw_template = raw_template.replace('_url_image_3', thirdImageBase64);

                    // update fourth image
                    festivalData = firstTop5[3];
                    imagePath =
                        festivalData.editionName !== ''
                            ? `${slugify(festivalData.name)}-${festivalData.year}_${slugify(festivalData.editionName)}.jpg`
                            : `${slugify(festivalData.name)}-${festivalData.year}.jpg`;
                    const fourthImageBase64 = await imageUrlToBase64(`/assets/img/festivals/${imagePath}`);
                    raw_template = raw_template.replace('_url_image_4', fourthImageBase64);

                    // update fifth image
                    festivalData = firstTop5[4];
                    imagePath =
                        festivalData.editionName !== ''
                            ? `${slugify(festivalData.name)}-${festivalData.year}_${slugify(festivalData.editionName)}.jpg`
                            : `${slugify(festivalData.name)}-${festivalData.year}.jpg`;
                    const fifthImageBase64 = await imageUrlToBase64(`/assets/img/festivals/${imagePath}`);
                    raw_template = raw_template.replace('_url_image_5', fifthImageBase64);

                    this.$originalSvg.innerHTML = raw_template;

                    await resvg.initWasm(fetch('https://unpkg.com/@resvg/resvg-wasm/index_bg.wasm'));

                    const opts = {
                        fitTo: {
                            mode: 'width', // If you need to change the size
                            value: 800,
                        },
                    };

                    const svg = raw_template;
                    const resvgJS = new resvg.Resvg(svg, opts);
                    const pngData = resvgJS.render(svg, opts); // Output PNG data, Uint8Array
                    const pngBuffer = pngData.asPng();
                    this.resultBlob = new Blob([pngBuffer], { type: 'image/png' });
                    const svgURL = URL.createObjectURL(new Blob([pngBuffer], { type: 'image/png' }));
                    this.$result.src = svgURL;
                    /*
                    setTimeout(() => {
                        let pbx = document.createElement('img');
                        pbx.style.width = this.$originalSvg.querySelector('svg').style.width;
                        pbx.style.height = this.$originalSvg.querySelector('svg').style.height;

                        const finalSvg = this.$originalSvg.querySelector('svg').outerHTML;

                        pbx.addEventListener('load', async () => {
                            this.$result.getContext('2d').drawImage(pbx, 0, 0);
                            await this.$result.toBlob(
                                (blob: any) => {
                                    this.resultBlob = blob;
                                },
                                'image/jpeg',
                                1
                            );
                        });

                        pbx.src = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(finalSvg)));
                    }, 2000);*/
                }
            }
        }
    }
}

export default new ShareModalManager();
