import { userFollowArtistOnSelectedProvider } from './utils';

export const calculateFestivalScoreForUser = (userArtists: any, userGenresWeightedSorted: any, festivalShows: any) => {
    // console.log(userArtists, userGenresWeightedSorted, festivalShows);

    let percent = 0;

    let artistsNotAnalysed = 0;
    let artistsNoMatchAtAll = 0;

    let percentArtistLikeMatched = 0;
    let artistsArtistLikeMatchedCount = 0;

    let percentTrackLikeMatched = 0;
    let artistsTrackLikeMatchedCount = 0;

    let percentSubscribeGenreMatched = 0;
    let artistsSubscribeMatchedCount = 0;

    festivalShows.forEach((show: any) => {
        const artistOfFestival = show.artist;

        if (artistOfFestival.spotifyUrl !== '') {
            //&& artist.applemusicUrl !== '' && artist.deezerUrl !== '') {
            let hasMatchArtistByTrackLike = false;
            let hasMatchArtistByArtistLike = false;
            let hasMatchArtistBySubscribe = false;

            let artistProviderId = '';

            //if (selectedProvider === PROVIDERS.SPOTIFY) {
            artistProviderId = artistOfFestival.spotifyUrl
                .replace('https://open.spotify.com/artist/', '')
                .replace('https://open.spotify.com/intl-fr/artist/', '')
                .replace('https://api.spotify.com/v1/artists/', '');
            if (artistProviderId.indexOf('?si=') !== -1) {
                artistProviderId = artistProviderId.substring(0, artistProviderId.indexOf('?'));
            }
            /*} else if (selectedProvider === PROVIDERS.APPLE_MUSIC) {
                artistProviderId = artist.applemusicUrl.substr(-(artist.applemusicUrl.length - artist.applemusicUrl.lastIndexOf('/')) + 1);
            }*/

            hasMatchArtistBySubscribe = userFollowArtistOnSelectedProvider(userArtists, artistProviderId);

            let artistOfFestivalFinalGenres = [];

            if (artistOfFestival.spotifyGenres && artistOfFestival.spotifyGenres.length > 0) {
                artistOfFestivalFinalGenres = artistOfFestival.spotifyGenres;
            } else if (artistOfFestival.spotifyRelatedGenres && artistOfFestival.spotifyRelatedGenres.length > 0) {
                artistOfFestivalFinalGenres = artistOfFestival.spotifyRelatedGenres;
            }

            artistOfFestivalFinalGenres.forEach((genreArtistOfFestival: any) => {
                userGenresWeightedSorted.forEach((userGenre: any) => {
                    if (genreArtistOfFestival.name === userGenre.label) {
                        userGenre.artists.forEach((userGenreArtist: any) => {
                            if (userGenreArtist.matchingRank === 1) {
                                // track like level
                                hasMatchArtistByTrackLike = true;
                            }
                        });
                        userGenre.artists.forEach((userGenreArtist: any) => {
                            if (userGenreArtist.matchingRank === 2) {
                                // artist like level
                                hasMatchArtistByArtistLike = true;
                                hasMatchArtistByTrackLike = false;
                            }
                        });
                    }
                });
            });

            if (hasMatchArtistBySubscribe) {
                artistsSubscribeMatchedCount += 1;
            } else {
                if (hasMatchArtistByTrackLike) {
                    artistsTrackLikeMatchedCount += 1;
                }
                if (hasMatchArtistByArtistLike) {
                    artistsArtistLikeMatchedCount += 1;
                    if (hasMatchArtistByTrackLike) {
                        artistsTrackLikeMatchedCount -= 1;
                    }
                }
            }
            if (!hasMatchArtistByTrackLike && !hasMatchArtistByArtistLike && !hasMatchArtistBySubscribe) {
                artistsNoMatchAtAll += 1;
            }
        } else {
            artistsNotAnalysed += 1;
        }
    });

    percentArtistLikeMatched = Math.floor((artistsArtistLikeMatchedCount * 100) / festivalShows.length);
    percentTrackLikeMatched = Math.floor((artistsTrackLikeMatchedCount * 100) / festivalShows.length);
    percentSubscribeGenreMatched = Math.floor((artistsSubscribeMatchedCount * 100) / festivalShows.length);

    percent = Math.floor(
        (artistsArtistLikeMatchedCount * 100) / festivalShows.length + (artistsTrackLikeMatchedCount * 100) / festivalShows.length + (artistsSubscribeMatchedCount * 100) / festivalShows.length
    );
    //percent = (artistsArtistLikeMatchedCount * 2 + artistsTrackLikeMatchedCount + artistsTrackLikeMatchedCount * 3) / 6;

    /*console.log(percent, festivalShows.length, artistsNotAnalysed, artistsNoMatchAtAll);
    console.log(percentArtistLikeMatched, artistsArtistLikeMatchedCount);
    console.log(percentTrackLikeMatched, artistsTrackLikeMatchedCount);
    console.log(percentSubscribeGenreMatched, artistsSubscribeMatchedCount);*/

    return {
        percent,
        percentArtistLike: percentArtistLikeMatched,
        percentTrackLike: percentTrackLikeMatched,
        percentSubscribe: percentSubscribeGenreMatched,
    };
};
