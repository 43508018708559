import SelectFestivalManager from '../select-festival-manager';
import { searchSpotifyArtist } from '../spotify';
import { getSpotifyClientAccessToken } from '../spotify/authCodeWithPkce';
import { displayLoader } from '../ui';
import UserManager from '../user-manager';
import { LINEFEST_USER_ARTISTS, LineFestError } from '../utils';

declare const MusicKit: any;
declare const i18next: any;

export const processAppleMusicData = async () => {
    return new Promise(async (processApplemusicResolve: any, processApplemusicReject: any) => {
        let music = MusicKit.getInstance();

        try {
            const musicUserToken: string = await music.authorize();
            console.log('logged with Apple Music: ', musicUserToken);

            if (UserManager.userFollowingArtists.length === 0) {
                // process
                // 1. call v1/me/library/artists

                SelectFestivalManager.$loginsBlock.classList.add('d-none');
                displayLoader(SelectFestivalManager.$artistsList, 'fetching_artists_library');

                let myArtists = [];
                let myLibraryArtists: any = [];

                const apiCall = async (userFollowingArtists: any, next?: string) => {
                    try {
                        const url = next ? next : 'v1/me/library/artists';
                        const myArtistsResponse = await music.api.music(url);
                        if (myArtistsResponse && myArtistsResponse.data && myArtistsResponse.data.data) {
                            for (let i = 0; i < myArtistsResponse.data.data.length; i++) {
                                let artist = {
                                    name: myArtistsResponse.data.data[i].attributes.name,
                                    matchingRank: 2,
                                };
                                userFollowingArtists.push(artist);
                            }
                            if (myArtistsResponse.data.next) {
                                await apiCall(userFollowingArtists, myArtistsResponse.data.next);
                            }
                        }
                    } catch (error: any) {
                        throw new LineFestError(error);
                    }
                };

                await apiCall(myLibraryArtists);

                const spotifyClientAccessToken = await getSpotifyClientAccessToken();

                console.log(myLibraryArtists);

                // 2. for each internal artist name, search catalog infos

                // test local artists
                let localArtists: any = localStorage.getItem(LINEFEST_USER_ARTISTS);

                if (localArtists) {
                    localArtists = JSON.parse(localArtists);
                }
                console.log(localArtists);

                const $loaderMessage = document.querySelector('.loader-container .message');

                for (let i = 0; i < myLibraryArtists.length; i++) {
                    const libraryArtist = myLibraryArtists[i];
                    if ($loaderMessage) {
                        const percent = Math.floor((i * 100) / myLibraryArtists.length);

                        if ($loaderMessage) {
                            $loaderMessage.innerHTML =
                                i18next.t('fetching_artists_metadatas').replace('_nb_', myLibraryArtists.length) +
                                '<br/><i>' +
                                i18next.t('fetching_artists_library_first_time') +
                                '</i><br/> ' +
                                percent +
                                '%';
                        }
                    }

                    if (localArtists && localArtists.hasOwnProperty(libraryArtist.name)) {
                        myArtists.push(localArtists[libraryArtist.name]);
                    } else {
                        const searchedArtist = await searchSpotifyArtist(libraryArtist.name, spotifyClientAccessToken);
                        if (searchedArtist && searchedArtist.artists && searchedArtist.artists.items && searchedArtist.artists.items.length > 0) {
                            let artist = searchedArtist.artists.items[0];
                            artist.matchingRank = 2;
                            myArtists.push(artist);
                        }
                    }
                }
                console.log(myArtists);

                UserManager.storeUserArtists(
                    myLibraryArtists.map((artist: any) => artist.name),
                    myArtists
                );

                UserManager.userFollowingArtists = myArtists;

                UserManager.updateGenres();
            }

            processApplemusicResolve(true);
        } catch (error) {
            console.log(error);
            processApplemusicReject(false);
        }
    });
};
