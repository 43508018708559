import { displayLoader, hideSelectionModes } from './ui';
import { processSpotifyData, spotifyClientId } from './spotify';
import { SPOTIFY_ACCESS_TOKEN_LS_KEY, getSpotifyAccessToken, getSpotifyRefreshToken, hasSpotifyAccessTokenExpire, redirectToSpotifyAuthCodeFlow } from './spotify/authCodeWithPkce';
import { LINEFEST_FESTIVALS_MATCHES, LINEFEST_MODE, LINEFEST_SELECTED_PROVIDER, MODES, PROVIDERS, code, getFestivalData, slugify } from './utils';
import InternalDataManager from './internal-data-manager';
import DetailFestivalModalManager from './detail-festival-modal';
import { calculateFestivalScoreForUser } from './engine';
import UserManager from './user-manager';
import { processAppleMusicData } from './apple-music';
import {
    DEEZER_ACCESS_TOKEN_EXPIRATION_TS_LS_KEY,
    DEEZER_ACCESS_TOKEN_LS_KEY,
    hasDeezerAccessTokenExpire,
    processDeezerMusicData,
    redirectToDeezerAuthCodeFlow,
    saveDeezerAccessTokenWithExpiresTS,
} from './deezer';
import ShareModalManager from './share-manager';

let SuggestFestivalManagerInstance: any;

declare const Sentry: any;
declare const i18next: any;
declare const dayjs: any;
declare const $: any;

export class SuggestFestivalManager {
    public $selectionModeLight: any = null;

    public $suggestAFestivalButton: any = null;
    public $suggestAFestivalPanel: any = null;

    public $loginsBlock: any = null;

    public $btnSpotifyLogin: any = null;
    public $btnAppleMusicLogin: any = null;
    public $btnDeezerLogin: any = null;

    public $btnShare: any = null;

    // public $btnAmazonMusicLogin = document.querySelector('.btn-amazon-music-login');

    public $resultsContainer: any = null;

    private calculationDone = false;

    constructor() {
        if (!SuggestFestivalManagerInstance) {
            SuggestFestivalManagerInstance = this;
        }
        return SuggestFestivalManagerInstance;
    }

    init() {
        return new Promise((suggestFestivalManagerInitResolve, suggestFestivalManagerInitReject) => {
            this.$suggestAFestivalButton = document.querySelector('.selection-mode .suggest-a-festival');
            this.$suggestAFestivalPanel = document.querySelector('.suggest-a-festival-panel');

            if (this.$suggestAFestivalButton) {
                this.$suggestAFestivalButton.addEventListener('click', this.showSuggestPanel.bind(this));
            }

            this.$loginsBlock = document.querySelector('.suggest-a-festival-panel .logins');
            this.$resultsContainer = document.querySelector('.suggest-a-festival-panel .results-container');

            this.$btnSpotifyLogin = document.querySelector('.suggest-a-festival-panel .btn-spotify-login');

            if (this.$btnSpotifyLogin) {
                this.$btnSpotifyLogin.addEventListener('click', this.loginWithSpotify.bind(this));
            }

            this.$btnAppleMusicLogin = document.querySelector('.suggest-a-festival-panel .btn-apple-music-login');

            if (this.$btnAppleMusicLogin) {
                this.$btnAppleMusicLogin.addEventListener('click', this.loginWithAppleMusic.bind(this));
            }

            this.$btnDeezerLogin = document.querySelector('.suggest-a-festival-panel .btn-deezer-login');

            if (this.$btnDeezerLogin) {
                this.$btnDeezerLogin.addEventListener('click', this.loginWithDeezer.bind(this));
            }

            this.$selectionModeLight = document.querySelector('.selection-mode-light');

            this.$btnShare = document.querySelector('.suggest-a-festival-panel .btn-share');

            if (this.$btnShare) {
                this.$btnShare.addEventListener('click', this.openShareModal.bind(this));
            }

            suggestFestivalManagerInitResolve(true);
        });
    }

    openShareModal() {
        ShareModalManager.show();
    }

    showSuggestPanel() {
        console.log('showSuggestPanel');

        if (this.$suggestAFestivalPanel) {
            this.$suggestAFestivalPanel.classList.remove('d-none');
            hideSelectionModes();
            localStorage.setItem(LINEFEST_MODE, MODES.SUGGEST);
            if (this.$selectionModeLight) {
                this.$selectionModeLight.classList.remove('d-none');
                const $suggestButton = this.$selectionModeLight.querySelector('.btn[data-action="suggest-a-festival"]');
                if ($suggestButton) {
                    $suggestButton.classList.add('active');
                }
                const $selectButton = this.$selectionModeLight.querySelector('.btn[data-action="select-a-festival"]');
                if ($selectButton) {
                    $selectButton.classList.remove('active');
                }
            }

            if (UserManager.userFollowingArtists.length !== 0 && !this.calculationDone) {
                this.$loginsBlock.classList.add('d-none');
                this.processSuggest();
            }
            if (UserManager.userFollowingArtists.length === 0) {
                this.$resultsContainer.classList.add('d-none');
            }
        }
    }

    hideSuggestPanel() {
        if (this.$suggestAFestivalPanel) {
            this.$suggestAFestivalPanel.classList.add('d-none');
        }
    }

    hideSuggestPanelResults() {
        if (this.$resultsContainer) {
            this.$resultsContainer.classList.add('d-none');
        }
    }

    showSuggestPanelLogins() {
        if (this.$loginsBlock) {
            this.$loginsBlock.classList.remove('d-none');
        }
    }

    loginWithSpotify() {
        localStorage.setItem(LINEFEST_SELECTED_PROVIDER, PROVIDERS.SPOTIFY);
        redirectToSpotifyAuthCodeFlow(spotifyClientId);
    }

    async loginWithAppleMusic() {
        InternalDataManager.selectedProvider = PROVIDERS.APPLE_MUSIC;
        localStorage.setItem(LINEFEST_SELECTED_PROVIDER, PROVIDERS.APPLE_MUSIC);
        this.$loginsBlock.classList.add('d-none');
        this.$resultsContainer.classList.remove('d-none');
        displayLoader(this.$resultsContainer, 'fetching_artists_library');
        try {
            await processAppleMusicData();
            this.processSuggest();
        } catch (error) {
            this.$resultsContainer.classList.add('d-none');
            this.$loginsBlock.classList.remove('d-none');
            const $loginError = this.$loginsBlock.querySelector('.error');
            if ($loginError) {
                $loginError.innerHTML = i18next.t('music_account_login_error');
            }
        }
    }

    loginWithDeezer() {
        InternalDataManager.selectedProvider = PROVIDERS.DEEZER;
        localStorage.setItem(LINEFEST_SELECTED_PROVIDER, PROVIDERS.DEEZER);
        redirectToDeezerAuthCodeFlow();
        this.$loginsBlock.classList.add('d-none');
    }

    loginWithAmazonMusic() {}

    async initSuggest() {
        console.log('initSuggest');
        this.showSuggestPanel();

        if (InternalDataManager.selectedProvider === PROVIDERS.SPOTIFY) {
            try {
                const localAccessToken = localStorage.getItem(SPOTIFY_ACCESS_TOKEN_LS_KEY);

                let accessToken = null;

                if (!localAccessToken && code) {
                    accessToken = await getSpotifyAccessToken(spotifyClientId, code);
                } else {
                    accessToken = localAccessToken;
                    const accessTokenExpirated = hasSpotifyAccessTokenExpire();
                    if (accessTokenExpirated) {
                        accessToken = await getSpotifyRefreshToken(spotifyClientId);
                    }
                }

                console.log(accessToken, UserManager.userFollowingArtists.length);

                if (accessToken) {
                    this.$loginsBlock.classList.add('d-none');
                    this.$resultsContainer.classList.remove('d-none');
                    UserManager.showLogoutButton();
                    UserManager.hideLoginButton();
                    try {
                        displayLoader(this.$resultsContainer, 'fetching_artists_library');

                        if (this.$resultsContainer) {
                            scroll({
                                top: this.$resultsContainer.getBoundingClientRect().top - 100,
                                behavior: 'smooth',
                            });
                        }

                        await processSpotifyData(accessToken);

                        this.processSuggest();
                    } catch (error: any) {
                        let e: any = error;
                        Sentry.captureException(error);
                        if (this.$resultsContainer) {
                            this.$resultsContainer.classList.add('error');
                            this.$resultsContainer.innerHTML = e.toString();
                        }
                    }
                } else {
                    if (UserManager.userFollowingArtists.length === 0) {
                        this.$resultsContainer.classList.add('d-none');
                    }
                }
            } catch (error: any) {
                let e: any = error;
                Sentry.captureException(error);
                if (this.$resultsContainer) {
                    this.$loginsBlock.classList.add('d-none');
                    this.$resultsContainer.classList.add('error');
                    this.$resultsContainer.innerHTML = e.toString();
                }
            }
        } else if (InternalDataManager.selectedProvider === PROVIDERS.DEEZER) {
            try {
                // Get access_token in hash url
                const parsedHash = new URLSearchParams(window.location.hash.substring(1));
                const parsedAccessToken = parsedHash.get('access_token');
                const parsedExpires = parsedHash.get('expires');

                let accessToken = '';
                let accessTokenExpires = null;

                if (!parsedAccessToken) {
                    const localAccessToken = localStorage.getItem(DEEZER_ACCESS_TOKEN_LS_KEY);
                    if (localAccessToken) {
                        accessToken = localAccessToken;
                        const localAccessTokenTS = localStorage.getItem(DEEZER_ACCESS_TOKEN_EXPIRATION_TS_LS_KEY);
                        if (localAccessTokenTS) {
                            if (hasDeezerAccessTokenExpire()) {
                                redirectToDeezerAuthCodeFlow();
                            }
                        }
                    }
                    // TODO CONTROL EXPIRES
                } else {
                    accessToken = parsedAccessToken;
                    accessTokenExpires = parsedExpires;
                    saveDeezerAccessTokenWithExpiresTS(parsedAccessToken, accessTokenExpires);
                }

                if (accessToken !== '') {
                    /*const localAccessToken = localStorage.getItem(DEEZER_ACCESS_TOKEN_LS_KEY);

                    let accessToken = null;

                    if (!localAccessToken) {
                        accessToken = await getDeezerAccessToken(code);
                    } else {
                        accessToken = localAccessToken;
                        const accessTokenExpirated = hasDeezerAccessTokenExpire();
                        if (accessTokenExpirated) {
                            accessToken = await getDeezerAccessToken(code);
                        }
                    }*/

                    this.$loginsBlock.classList.add('d-none');
                    this.$resultsContainer.classList.remove('d-none');
                    if (accessToken !== '') {
                        try {
                            displayLoader(this.$resultsContainer, 'fetching_artists_library');

                            await processDeezerMusicData(accessToken);

                            this.processSuggest();
                        } catch (error: any) {
                            let e: any = error;
                            Sentry.captureException(error);
                            if (this.$resultsContainer) {
                                this.$resultsContainer.classList.add('error');
                                this.$resultsContainer.innerHTML = e.toString();
                            }
                        }
                    }
                }
            } catch (error: any) {
                let e: any = error;
                Sentry.captureException(error);
                if (this.$resultsContainer) {
                    this.$loginsBlock.classList.add('d-none');
                    this.$resultsContainer.classList.add('error');
                    this.$resultsContainer.innerHTML = e.toString();
                }
            }
        } else {
            this.$resultsContainer.classList.add('d-none');
        }
    }

    async processSuggest() {
        console.log('processSuggest: ', this.calculationDone);

        if (this.$resultsContainer) {
            this.$resultsContainer.innerHTML = '';

            if (!this.calculationDone) {
                displayLoader(this.$resultsContainer, 'processing_festivals_suggestion');
            }

            if (InternalDataManager.festivals) {
                const $loaderMessage = document.querySelector('.loader-container .message');

                let enhancedFestivals = [];

                // Control local backup and skip if needed
                let localFestivalsMatches: any = localStorage.getItem(LINEFEST_FESTIVALS_MATCHES);
                if (localFestivalsMatches) {
                    localFestivalsMatches = JSON.parse(localFestivalsMatches);
                    if (localFestivalsMatches && localFestivalsMatches.length !== 0) {
                        for (let i = 0; i < InternalDataManager.festivals.length; i++) {
                            if ($loaderMessage) {
                                const percent = Math.floor((i * 100) / InternalDataManager.festivals.length);
                                if ($loaderMessage) {
                                    $loaderMessage.innerHTML = i18next.t('processing_festivals_suggestion') + ' <br/> ' + percent + '%';
                                }
                            }
                            for (let j = 0; j < localFestivalsMatches.length; j++) {
                                if (localFestivalsMatches[j].id === InternalDataManager.festivals[i].id) {
                                    const festival = Object.assign({}, InternalDataManager.festivals[i]);
                                    festival.matchingScores = localFestivalsMatches[j].matchingScores;
                                    enhancedFestivals.push(festival);
                                }
                            }
                        }
                    }
                } else {
                    for (let i = 0; i < InternalDataManager.festivals.length; i++) {
                        const festival = Object.assign({}, InternalDataManager.festivals[i]);
                        const festivalSlugName = slugify(festival.name);
                        const festivalSlugEditionName = slugify(festival.editionName);
                        const festivalData = await getFestivalData(festivalSlugName, festival.year, festivalSlugEditionName);

                        if ($loaderMessage) {
                            const percent = Math.floor((i * 100) / InternalDataManager.festivals.length);

                            if ($loaderMessage) {
                                $loaderMessage.innerHTML = i18next.t('processing_festivals_suggestion') + ' <br/> ' + percent + '%';
                            }
                        }

                        const score = calculateFestivalScoreForUser(UserManager.userFollowingArtists, UserManager.userGenresWeightedSorted, festivalData.shows);

                        festival.matchingScores = score;
                        festival.genresStats = festivalData.genresStats;
                        enhancedFestivals.push(festival);
                    }
                }

                enhancedFestivals = enhancedFestivals.sort((festivalA: any, festivalB: any) => festivalB.matchingScores.percent - festivalA.matchingScores.percent);

                UserManager.storeUserFestivalsMatches(enhancedFestivals);

                this.$resultsContainer.innerHTML = '';

                enhancedFestivals.forEach((enhancedFestival) => {
                    const $enhancedFestivalDiv = document.createElement('div');
                    $enhancedFestivalDiv.classList.add('suggested-festival');

                    $enhancedFestivalDiv.setAttribute('data-festival-id', enhancedFestival.id);
                    $enhancedFestivalDiv.setAttribute('data-festival-slug', slugify(enhancedFestival.name));
                    $enhancedFestivalDiv.setAttribute('data-festival-year', enhancedFestival.year);
                    $enhancedFestivalDiv.setAttribute('data-festival-edition-name', slugify(enhancedFestival.editionName));

                    // IMAGE
                    let imagePath =
                        enhancedFestival.editionName !== ''
                            ? `/assets/img/festivals/${slugify(enhancedFestival.name)}-${enhancedFestival.year}_${slugify(enhancedFestival.editionName)}.jpg`
                            : `/assets/img/festivals/${slugify(enhancedFestival.name)}-${enhancedFestival.year}.jpg`;

                    // CIRCULAR
                    const radius = 190 / 2 - 10;
                    const circumference = 3.14 * radius * 2;
                    const strokeDashOffsetPercent = Math.round(circumference * ((100 - enhancedFestival.matchingScores.percent) / 100)) + 'px';
                    const strokeDashOffsetGenre = Math.round(circumference * ((100 - enhancedFestival.matchingScores.percentArtistLike) / 100)) + 'px';
                    const strokeDashOffsetRelatedGenre = Math.round(circumference * ((100 - enhancedFestival.matchingScores.percentTrackLike) / 100)) + 'px';
                    const strokeDashOffsetSubscribe = Math.round(circumference * ((100 - enhancedFestival.matchingScores.percentSubscribe) / 100)) + 'px';
                    let textX = 30;
                    if (enhancedFestival.matchingScores.percent < 100) {
                        textX = 41;
                    }
                    let userDetailedStats: any = {};
                    userDetailedStats.percent = enhancedFestival.matchingScores.percent;
                    userDetailedStats.percentArtistLike = enhancedFestival.matchingScores.percentArtistLike;
                    userDetailedStats.percentTrackLike = enhancedFestival.matchingScores.percentTrackLike;
                    userDetailedStats.percentSubscribe = enhancedFestival.matchingScores.percentSubscribe;
                    userDetailedStats = Object.fromEntries(Object.entries(userDetailedStats).sort(([, a]: any, [, b]: any) => b - a));
                    let circlesHTML = '';
                    let userStatsLegendHTML = '';

                    let maxStatIsNotPercent = 0;
                    for (let stat in userDetailedStats) {
                        if (stat !== 'percent' && userDetailedStats[stat] > maxStatIsNotPercent) {
                            maxStatIsNotPercent = userDetailedStats[stat];
                        }
                    }
                    // console.log(enhancedFestival.name, userDetailedStats.percent, maxStatIsNotPercent, userDetailedStats);
                    const offsetForStats = userDetailedStats.percent - maxStatIsNotPercent;

                    // https://github.com/nikitahl/svg-circle-progress-generator

                    for (let stat in userDetailedStats) {
                        let color = '';
                        let legendText = '';
                        switch (stat) {
                            case 'percent':
                                color = '#079992';
                                break;
                            case 'percentArtistLike':
                                color = '#38ada9';
                                legendText = i18next.t('legends_surely_like');
                                break;
                            case 'percentTrackLike':
                                color = '#78e08f';
                                legendText = i18next.t('legends_may_like');
                                break;
                            case 'percentSubscribe':
                                color = '#b8e994';
                                legendText = i18next.t('legends_follow');
                                break;

                            default:
                                break;
                        }
                        if (stat !== 'percent' && userDetailedStats[stat] !== 0) {
                            const finalValue = userDetailedStats[stat] + offsetForStats;
                            let offset = Math.round(circumference * ((100 - finalValue) / 100)) + 'px';
                            circlesHTML += `
                                    <circle r="85" cx="85" cy="85" stroke="${color}" stroke-width="30" stroke-linecap="round" stroke-dashoffset="${offset}" fill="transparent" stroke-dasharray="533.8000000000001px"></circle>
                                `;
                        }

                        if (stat !== 'percent' && userDetailedStats[stat] !== 0) {
                            userStatsLegendHTML += `
                                <div class="user-stats-legend-item">
                                    <div class="user-stats-legend-item_color" style="background-color: ${color}">${userDetailedStats[stat]}%</div>
                                    <span>${legendText}</span>
                                </div>
                            `;
                        }
                    }

                    // DATE
                    let dates = '';
                    if (dayjs(enhancedFestival.start_datetime).format('MM') !== dayjs(enhancedFestival.end_datetime).format('MM')) {
                        dates = `${dayjs(enhancedFestival.start_datetime).format('DD')} ${dayjs(enhancedFestival.start_datetime).format('MMM')} - ${dayjs(enhancedFestival.end_datetime).format(
                            'DD'
                        )} ${dayjs(enhancedFestival.end_datetime).format('MMM')} ${enhancedFestival.year}`;
                    } else {
                        dates = `${dayjs(enhancedFestival.start_datetime).format('DD')}-${dayjs(enhancedFestival.end_datetime).format('DD')} ${dayjs(enhancedFestival.end_datetime).format('MMM')} ${
                            enhancedFestival.year
                        }`;
                    }

                    // FESTIVAL GENRES STATS
                    let festivalGenresStatsHTML = '';
                    for (let genreStat in enhancedFestival.genresStats) {
                        if (enhancedFestival.genresStats[genreStat].percent !== 0) {
                            festivalGenresStatsHTML += `
                                <div class="festival-genres-stats">
                                    <div class="percent">
                                        ${enhancedFestival.genresStats[genreStat].percent}%
                                    </div>
                                    <div class="name">
                                        ${genreStat}
                                    </div>
                                </div>
                            `;
                        }
                    }

                    /*$enhancedFestivalDiv.innerHTML = `
                        <div class="left-side">
                            <div class="image-and-infos">
                                <img src="${imagePath}" alt="${enhancedFestival.name}" title="${enhancedFestival.name}" loading="lazy"/>
                                <div class="infos">
                                    <span class="name">${enhancedFestival.name}</span>
                                    <div class="date">
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3 9H21M7 3V5M17 3V5M6 12H8M11 12H13M16 12H18M6 15H8M11 15H13M16 15H18M6 18H8M11 18H13M16 18H18M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z" stroke="#f1c40f" stroke-width="2" stroke-linecap="round"/>
                                        </svg>
                                        <span>
                                            ${dates}
                                        </span>
                                    </div>
                                    <div class="venue">
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 20L3 17V4L9 7M9 20L15 17M9 20V7M15 17L21 20V7L15 4M15 17V4M9 7L15 4" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        <span>${enhancedFestival.city.name}, ${enhancedFestival.city.country.name}</span>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="festivals-genres-stats">
                                ${festivalGenresStatsHTML}
                            </div>
                        </div>
                        <div class="right-side">
                            <span><b>${i18next.t('matching_score')}</b></span>
                            <svg width="170" height="170" viewBox="-21.25 -21.25 212.5 212.5" version="1.1" xmlns="http://www.w3.org/2000/svg" style="transform:rotate(-90deg)">
                                <circle r="85" cx="85" cy="85" fill="transparent" stroke="#272529" stroke-width="30" stroke-dasharray="533.8000000000001px" stroke-dashoffset="0"></circle>
                                ${circlesHTML}
                                <text x="${textX}px" y="115px" fill="#fff" font-size="45px" font-weight="bold" style="transform:rotate(90deg) translate(0px, -186px)">${
                        enhancedFestival.matchingScores.percent
                    }%</text>
                            </svg>
                            <span>${i18next.t('of_which')}</span>
                            <div class="user-stats-legend">
                                ${userStatsLegendHTML}
                            </div>
                        </div>
                    `;*/

                    const infos = `
                        <div class="infos">
                            <div class="legend">
                                <div class="user-stats-legend">
                                    ${userStatsLegendHTML}
                                </div>
                            </div>
                            <span class="name">${enhancedFestival.name}</span>
                            <div class="date">
                                <span>
                                    ${dates}
                                </span>
                            </div>
                            <div class="venue">
                                <span>${enhancedFestival.city.name}, ${enhancedFestival.city.country.name}</span>
                            </div>
                            <div class="cta">
                                <button class="btn btn-yellow-light btn-see-in-detail">${i18next.t('see-in-detail')}</button>
                            </div>
                        </div>
                    `;

                    $enhancedFestivalDiv.innerHTML = `
                        <div class="first-line"> 
                            <div class="left-side">
                            <svg width="170" height="170" viewBox="-21.25 -21.25 212.5 212.5" version="1.1" xmlns="http://www.w3.org/2000/svg" style="transform:rotate(-90deg)">
                                    <circle r="85" cx="85" cy="85" fill="transparent" stroke="#272529" stroke-width="30" stroke-dasharray="533.8000000000001px" stroke-dashoffset="0"></circle>
                                    ${circlesHTML}
                                    <text x="${textX}px" y="115px" fill="#fff" font-size="45px" font-weight="bold" style="transform:rotate(90deg) translate(0px, -186px)">${
                        enhancedFestival.matchingScores.percent
                    }%</text>
                                </svg>
                                
                            </div>
                            <div class="right-side">
                                <div class="image-and-infos">
                                    <picture>
                                        <source srcset="${imagePath}.webp" type="image/webp">
                                        <source srcset="${imagePath}" type="image/jpeg"> 
                                        <img src="${imagePath}" alt="${enhancedFestival.name}" title="${enhancedFestival.name}" loading="lazy"/>
                                    </picture>
                                    ${infos}
                                </div>
                            </div>
                        </div>
                        <div class="sub-infos">
                            ${infos}
                        </div>
                        <div class="cta">
                            <button class="btn btn-yellow-light btn-see-in-detail">
                                ${i18next.t('see-in-detail')}
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                </svg>
                            </button>
                        </div>
                    `;

                    $enhancedFestivalDiv.addEventListener('click', this.openFestivalDetail.bind(this));

                    this.$resultsContainer.appendChild($enhancedFestivalDiv);
                });

                /*const $seeInDetailButtons = document.querySelectorAll('.suggest-a-festival-panel .btn-see-in-detail');
                if ($seeInDetailButtons.length > 0) {
                    $seeInDetailButtons.forEach(($seeInDetailButton: any) => {
                        $seeInDetailButton
                    });
                }*/

                if (this.$btnShare) {
                    this.$btnShare.style.display = 'flex';
                    //this.$btnShare.style.display = 'none';
                }

                this.calculationDone = true;
            }
        }
    }

    openFestivalDetail(ev: any) {
        const festivalSlug = ev.currentTarget.getAttribute('data-festival-slug');
        const festivalId = parseInt(ev.currentTarget.getAttribute('data-festival-id'), 10);
        const festivalYear = parseInt(ev.currentTarget.getAttribute('data-festival-year'), 10);
        const festivalEditionName = ev.currentTarget.getAttribute('data-festival-edition-name');
        DetailFestivalModalManager.show({
            festivalId,
            festivalSlug,
            festivalYear,
            festivalEditionName,
        });
    }
}

export default new SuggestFestivalManager();
