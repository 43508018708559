import { searchSpotifyArtist } from '../spotify';
import { getSpotifyClientAccessToken } from '../spotify/authCodeWithPkce';
import UserManager from '../user-manager';
import { LINEFEST_USER_ARTISTS, LineFestError } from '../utils';

const deezerAppId = `${import.meta.env.VITE_DEEZER_APP_ID}`;
const deezerSecret = `${import.meta.env.VITE_DEEZER_APP_SECRET}`;

declare const fetchJsonp: any;
declare const Sentry: any;
declare const i18next: any;

export const DEEZER_ACCESS_TOKEN_LS_KEY = 'linefest_deezer_access_token';
export const DEEZER_ACCESS_TOKEN_EXPIRATION_TS_LS_KEY = 'linefest_deezer_access_token_expiration_timestamp';

export const extractAccessTokenFromURLOrLS = () => {
    return new Promise(async (extractResolve: any, extractReject: any) => {
        try {
            // Get access_token in hash url
            const parsedHash = new URLSearchParams(window.location.hash.substring(1));
            const parsedAccessToken = parsedHash.get('access_token');
            const parsedExpires = parsedHash.get('expires');
            let accessTokenExpires = null;
            let deezerAccessToken = '';

            if (!parsedAccessToken) {
                const localAccessToken = localStorage.getItem(DEEZER_ACCESS_TOKEN_LS_KEY);
                if (localAccessToken) {
                    deezerAccessToken = localAccessToken;
                    const localAccessTokenTS = localStorage.getItem(DEEZER_ACCESS_TOKEN_EXPIRATION_TS_LS_KEY);
                    if (localAccessTokenTS) {
                        if (hasDeezerAccessTokenExpire()) {
                            redirectToDeezerAuthCodeFlow();
                        }
                    }
                }
                // TODO CONTROL EXPIRES
            } else {
                deezerAccessToken = parsedAccessToken;
                accessTokenExpires = parsedExpires;
                saveDeezerAccessTokenWithExpiresTS(parsedAccessToken, accessTokenExpires);
            }
            let url = new URL(window.location.href);
            history.pushState({}, '', url.origin + url.pathname);
            extractResolve(deezerAccessToken);
        } catch (error) {
            extractReject(error);
        }
    });
};

export async function redirectToDeezerAuthCodeFlow() {
    const params = new URLSearchParams();
    params.append('app_id', deezerAppId);
    params.append('redirect_uri', `${import.meta.env.VITE_DEEZER_REDIRECT_URI}`);
    params.append('perms', 'basic_access');
    params.append('response_type', 'token');

    document.location = `https://connect.deezer.com/oauth/auth.php?${params.toString()}`;
}

export const saveDeezerAccessTokenWithExpiresTS = (access_token: string, expires: any) => {
    localStorage.setItem(DEEZER_ACCESS_TOKEN_LS_KEY, access_token);
    var tokenexpiration: Date = new Date();
    tokenexpiration.setSeconds(new Date().getSeconds() + parseInt(expires));
    localStorage.setItem(DEEZER_ACCESS_TOKEN_EXPIRATION_TS_LS_KEY, String(tokenexpiration.getTime()));
};

export async function getDeezerAccessToken(code: string) {
    const result = await fetch(`https://connect.deezer.com/oauth/access_token.php?app_id=${deezerAppId}&secret=${deezerSecret}&code=${code}&output=json`, {
        method: 'GET',
        mode: 'cors',
    });

    try {
        if (result.status === 200) {
            const { access_token, expires } = await result.json();
            localStorage.setItem(DEEZER_ACCESS_TOKEN_LS_KEY, access_token);
            var tokenexpiration: Date = new Date();
            tokenexpiration.setSeconds(new Date().getSeconds() + parseInt(expires));
            localStorage.setItem(DEEZER_ACCESS_TOKEN_EXPIRATION_TS_LS_KEY, String(tokenexpiration.getTime()));
            return access_token;
        } else {
            if (result.body) {
                const message: any = await result.json();
                throw new LineFestError(message.error_description);
            }
        }
    } catch (error: any) {
        throw new LineFestError(error);
    }
}

export const hasDeezerAccessTokenExpire = () => {
    const access_token_expiration_timestamp = Number(localStorage.getItem(DEEZER_ACCESS_TOKEN_EXPIRATION_TS_LS_KEY));
    const now = Date.now();
    return now > access_token_expiration_timestamp;
};

export async function getDeezerMe(token: string): Promise<any> {
    return new Promise(async (processDeezerResolve: any, processDeezerReject: any) => {
        var result = fetchJsonp(`https://api.deezer.com/user/me?output=jsonp&access_token=${token}`, {
            jsonpCallbackFunction: 'api_call',
        });
        result
            .then(function (response: any) {
                return response.json();
            })
            .then(function (json: any) {
                processDeezerResolve(json);
            })
            .catch(function (ex: any) {
                processDeezerReject(ex);
            });
    });
}

export async function getDeezerMeArtists(token: string): Promise<any> {
    return new Promise(async (processDeezerResolve: any, processDeezerReject: any) => {
        var result = fetchJsonp(`https://api.deezer.com/user/me/artists?output=jsonp&access_token=${token}`, {
            jsonpCallbackFunction: 'api_call',
        });
        result
            .then(function (response: any) {
                return response.json();
            })
            .then(function (json: any) {
                processDeezerResolve(json);
            })
            .catch(function (ex: any) {
                processDeezerReject(ex);
            });
    });
}

export async function getDeezerMeTracks(token: string): Promise<any> {
    return new Promise(async (processDeezerResolve: any, processDeezerReject: any) => {
        var result = fetchJsonp(`https://api.deezer.com/user/me/tracks?output=jsonp&access_token=${token}`, {
            jsonpCallbackFunction: 'api_call',
        });
        result
            .then(function (response: any) {
                return response.json();
            })
            .then(function (json: any) {
                processDeezerResolve(json);
            })
            .catch(function (ex: any) {
                processDeezerReject(ex);
            });
    });
}

export const processDeezerMusicData = async (accessToken: string) => {
    console.log('processDeezerMusicData');

    return new Promise(async (processDeezerResolve: any, processDeezerReject: any) => {
        try {
            if (UserManager.userFollowingArtists.length === 0) {
                let myArtists = [];
                let myLibraryArtists: any = [];

                const apiArtistsCall = async (userFollowingArtists: any, next?: string) => {
                    return new Promise(async (apicallDeezerResolve: any, apicallDeezerReject: any) => {
                        const url = next ? next : `https://api.deezer.com/user/me/artists?output=jsonp&access_token=${accessToken}`;
                        fetchJsonp(url, {
                            jsonpCallbackFunction: 'apiArtistsCall',
                        })
                            .then(function (response: any) {
                                return response.json();
                            })
                            .then(async (json: any) => {
                                if (json && json.error) {
                                    if (json.error && json.error.message && json.error.message === 'Invalid OAuth access token.') {
                                        redirectToDeezerAuthCodeFlow();
                                    }
                                }
                                if (json && json.data) {
                                    for (let i = 0; i < json.data.length; i++) {
                                        if (json.data[i].hasOwnProperty('name')) {
                                            let artist = {
                                                name: json.data[i].name,
                                                matchingRank: 2,
                                            };
                                            userFollowingArtists.push(artist);
                                        }
                                    }
                                    if (json.next) {
                                        apicallDeezerResolve(true);
                                        await apiArtistsCall(userFollowingArtists, json.next);
                                    } else {
                                        apicallDeezerResolve(true);
                                    }
                                }
                            })
                            .catch(function (ex: any) {
                                apicallDeezerReject(ex);
                            });
                    });
                };

                await apiArtistsCall(myLibraryArtists);

                const apiFavoritesCall = async (userFollowingArtists: any, next?: string) => {
                    return new Promise(async (apicallDeezerResolve: any, apicallDeezerReject: any) => {
                        const url = next ? next : `https://api.deezer.com/user/me/tracks?output=jsonp&access_token=${accessToken}`;
                        fetchJsonp(url, {
                            jsonpCallbackFunction: 'apiFavoritesCall',
                        })
                            .then(function (response: any) {
                                return response.json();
                            })
                            .then(async (json: any) => {
                                if (json && json.error) {
                                    if (json.error && json.error.message && json.error.message === 'Invalid OAuth access token.') {
                                        redirectToDeezerAuthCodeFlow();
                                    }
                                }
                                if (json && json.data) {
                                    for (let i = 0; i < json.data.length; i++) {
                                        if (json.data[i].hasOwnProperty('name')) {
                                            let artist = {
                                                name: json.data[i].name,
                                                matchingRank: 1,
                                            };
                                            userFollowingArtists.push(artist);
                                        }
                                    }
                                    if (json.next) {
                                        apicallDeezerResolve(true);
                                        await apiFavoritesCall(userFollowingArtists, json.next);
                                    } else {
                                        apicallDeezerResolve(true);
                                    }
                                }
                            })
                            .catch(function (ex: any) {
                                apicallDeezerReject(ex);
                            });
                    });
                };

                await apiFavoritesCall(myLibraryArtists);

                const spotifyClientAccessToken = await getSpotifyClientAccessToken();

                console.log(myLibraryArtists);

                // 3. for each internal artist name, search catalog infos

                // test local artists
                let localArtists: any = localStorage.getItem(LINEFEST_USER_ARTISTS);

                if (localArtists) {
                    localArtists = JSON.parse(localArtists);
                }
                console.log(localArtists);

                const $loaderMessage = document.querySelector('.loader-container .message');

                for (let i = 0; i < myLibraryArtists.length; i++) {
                    const libraryArtist = myLibraryArtists[i];
                    if ($loaderMessage) {
                        const percent = Math.floor((i * 100) / myLibraryArtists.length);

                        if ($loaderMessage) {
                            $loaderMessage.innerHTML =
                                i18next.t('fetching_artists_metadatas').replace('_nb_', myLibraryArtists.length) +
                                '<br/><i>' +
                                i18next.t('fetching_artists_library_first_time') +
                                '</i><br/> ' +
                                percent +
                                '%';
                        }
                    }

                    if (localArtists && localArtists.hasOwnProperty(libraryArtist.name)) {
                        myArtists.push(localArtists[libraryArtist.name]);
                    } else {
                        const searchedArtistRequest = await searchSpotifyArtist(libraryArtist.name, spotifyClientAccessToken);
                        if (searchedArtistRequest && searchedArtistRequest.artists && searchedArtistRequest.artists.items && searchedArtistRequest.artists.items.length > 0) {
                            let searchedArtist = searchedArtistRequest.artists.items[0];
                            searchedArtist.matchingRank = libraryArtist.matchingRank;
                            myArtists.push(searchedArtist);
                        }
                    }
                }
                console.log(myArtists);

                UserManager.storeUserArtists(
                    myLibraryArtists.map((artist: any) => artist.name),
                    myArtists
                );

                UserManager.userFollowingArtists = myArtists;

                UserManager.updateGenres();
            }

            processDeezerResolve(true);
        } catch (error) {
            console.log(error);
            processDeezerReject(false);
        }
    });
};
